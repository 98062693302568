.tabletPortrait .div__productDetail-topContainer{
    flex-direction: column;
    align-items: center;
}

.tabletPortrait .div__productDetail-images{
    width: 100%;
    align-items: center;
    flex-direction: row;
}

.tabletPortrait .div__productDetail-imagesSection{
   width: 100%;
   max-width: 460px;
   margin-left: -10%;
}

.tabletPortrait .div__productDetail-imagesSection {
    display: flex;
    justify-content: space-between;
}
.tabletPortrait .div__productDetail-content{
    width: 100%;
}


.tabletPortrait .div__productDetail-inventoryCart{
    width: 93.5%;
    border-radius: 10px;
    opacity: 1;
    margin-left: 15px;
}

.tabletPortrait .div__productDetail-imagesList{
    padding: 0px 3%;
}

.tabletPortrait .div__productDetail-content{
    margin-left: 0px;
}

.tabletPortrait .productPunDescription, .deliveryInstruction {
    width: 80%;
}

.tabletPortrait .productTitle, 
.tabletPortrait .productDetail,
.tabletPortrait .productPunDescription, 
.tabletPortrait .div__productDetail-content-rating, 
.tabletPortrait .div__productDetail-content-cartQuantity, 
.tabletPortrait .div__productDetail-content-sizeSelect{
    margin-left: 2% ;
}


.tabletPortrait .div__productDetail-imagesList{
    padding-left: 100px;
}
