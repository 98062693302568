@media (max-width: 600px) {
    
    .replyTicket-container__view{
        flex-direction: column;
    }
    
    .replyTicket-container__leftGallery, .replyTicket-container__rightGallery{
        width: 100%;
        margin: 10px 0px;
    }

    .div__dateRangePicker-container, .div__dropdown-input{
        padding: 0px;
    }
}

.mobile .admin__ticketsList{
    padding: 10px;
}