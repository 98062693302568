.tabletPortrait .myMemeSuggestions .myMemeSuggestions-searchInput{
    width: 82%;
}
.tabletPortrait .myMemeSuggestions-container .MuiGrid-item{
    width: 82%;
}

.tabletPortrait .myMemeSuggestions-imgEmptyList {
    width: 253px;
    height: 267px;
}

.tabletLandscape .myMemeSuggestions-imgEmptyList {
    width: 253px;
    height: 267px;
}

.tabletLandscape .imgCardPreview,
.tabletPortrait .imgCardPreview{
    height: 275px;
    width: 208px;
}