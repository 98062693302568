.tabletLandscape .myOrders__container {
  padding: 4% 4%;
}

.tabletPortrait .myOrder__card-content-container {
  flex-wrap: nowrap;
}

.tabletPortrait .myOrders__container {
  padding: 4% 4%;
}

.tabletLandscape .div__detail-item, .tabletPortrait .div__detail-item{
  margin: 20px 0px;
}