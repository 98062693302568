.mobile .vendorDashboard__gridContainer {
    display: flex;
    flex-direction: column;
}
.mobile .vendorDashboard__gridContainer--dispatchOrders{
    width: 95%;
}
.mobile .vendorDashboard__gridContainer .MuiGrid-item{
    max-width: 100%;
}

.mobile .vendorDashboard__header--right-addProduct-text{
    color: #f5f5f5;
    font-size: 13px;
    margin: 8px 0px;
    text-align: center;
    width: 100%;
}

.mobile .vendorDashboard__header .vendorDashboard__header--right-addProduct-button{
    width: 100%;
    height: 38px;
    font-size: clamp(0.8rem, 1vw, 1.2rem);
}

.mobile .vendorOrderCard__left{
    margin: 0px;
}

.mobile .vendorDashboard__header--right{
    width: 40%;
}

.mobile .vendorDashboard__header{
    align-items: flex-start;
}

.mobile .vendorOrderCard__left .vendorOrderCard__left-image{
    display: none;
}

.mobile .vendorDashboard__header{
    margin: 35px 0px;
}

.mobile .vendorDashboard__gridContainer--dispatchOrders .div__detail-item-content{
    padding: 10px 8px;
}

.mobile .vendorDashboard__gridContainer-vendorBankAccounts .div__detail-item.vendorDashboard__gridContainer--bankAccountsList {
    width: 95%;
}


@media (max-width:395px){
    
    .vendorDashboard__gridContainer-analytics .MuiGrid-container {
        display: flex;
        flex-direction: column;
    }

}