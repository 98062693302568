.vendorDashboard__gridContainer--latestTickets {
    margin-top: 50px;
    padding: 0px 2%;
}

.vendorDashboard__gridContainer--latestTickets .vendorDashboard__gridContainer--latestTickets-header {
    background-color: #212628;
    font-size: clamp(0.8rem,1.5vw,1.2rem);
    padding: 15px 30px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    border-bottom: 1px solid #707070;
}

.vendorDashboard__gridContainer--latestTickets-header-viewAllTickets {
    color: #b4ff4f;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: clamp(0.8rem,1vw,1.2rem);
}

.vendorDashboard__gridContainer--latestTickets {
    height: auto;
    width: 95%;
}

.vendorDashboard__gridContainer--latestTickets .vendorDashboard__gridContainer--latestTickets-icon {
    vertical-align: middle;
    width: 30px;
    height: 30px;
}

.vendorDashboard__gridContainer--latestTickets .vendorDashboard__gridContainer--latestTickets-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #212628;
    height: 100%;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;
    padding: 0px;
}

.vendorDashboard__gridContainer--latestTickets .vendorDashboard__gridContainer--latestTickets-content::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.vendorDashboard__gridContainer--latestTickets .vendorDashboard__gridContainer--latestTickets-content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.vendorDashboard__gridContainer--latestTickets-content .listTable {
    border-bottom: none;
}

.vendorDashboard__gridContainer--latestTickets-imgEmptyList {
    width: 405px;
    height: 285px;
}

