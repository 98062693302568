.mobile .div__cart-item-detail-itemQuantity {
    display: none;
}

.mobile .div__cart-item-inventoryDetail {
    flex-direction: column;
    align-items: flex-start;
    height: 70px;
    margin: 5px 0px;
}

.mobile .div__cart-item-inventoryQuantity .div__dropdown-input {
    width: 64px;
    margin-left: 0px;
    padding: 5px 0px;
}

.mobile .div__cart-item-inventoryQuantity .div__dropdown-input .MuiOutlinedInput-root.MuiInputBase-root {
    height: 30px;
}