.myMemeSuggestions-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.myMemeSuggestions-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;    
}

.myMemeSuggestions-headerText {
    display: flex;
    align-items: center;
    margin: 15px 5px;
    font-size: clamp(0.95rem, 2vw, 2rem);
}

#iconMemeSuggestion {
    font-size: 38px;
    margin-right: 5px;
    margin-bottom: -5px;
}

.myMemeSuggestions-overview {
    display: flex;
    margin: 10px 0px;
    justify-content: center;
}

.myMemeSuggestions-grid {
    flex: 0.65;
}

.myMemeSuggestions .myMemeSuggestions-searchInput {
    align-self: center;
    width: 72%;
    font-size: 20px;
    margin-top: 20px;
}

.myMemeSuggestions-emptyList {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
}

.myMemeSuggestions-imgEmptyList {
    width: 333px;
    height: 349px;
}

.myMemeSuggestions-lblEmptyListMessage,
.myMemeSuggestions-lblEmptyListSubMessage {
    margin: 20px 0px;
}

.myMemeSuggestions-lblEmptyListMessage {
    font-size: 22px;
}

.myMemeSuggestions-lblEmptyListSubMessage {
    font-size: 22px;
}

.myMemeSuggestions-container .myMemeSuggestions-btnSuggestMeme,
.myMemeSuggestions-container .myMemeSuggestions-btnSuggestMeme:hover {
    background: #86c232;
    color: white;
    align-self: center;
    font-size: 22px;
    width: 300px;
}

.myMemeSuggestions-popupContent {
    text-align: center;
    padding: 10px;
}

.myMemeSuggestions-popupContent-confirmQuestion {
    font-size: 25px;
}

.myMemeSuggestions-popupContent-confirmMessage {
    font-size: 20px;
    margin: 25px;
    color: #a5a5a5;
}


#btnInput.memeSuggestion_detail-addMemeSuggestion {
    font-size: clamp(0.9rem, 1.8vw, 1.5rem);
    background-color: #74a730;
    width: clamp(10rem, 17vw, 67rem);
}

.MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--add .MuiPaper-root {
    background-color: #585858;
}

.MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--view .MuiPaper-root {
    background-color: white;
}

.MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--add .MuiDrawer-paper,
.MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--view .MuiDrawer-paper {
    height: 80%;
}

.MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--view .btnCloseDrawer svg {
    color: #707070;
}

.MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--add .btnCloseDrawer svg {
    color: white;
}

.myMemeSuggestions-container .MuiGrid-container{
    width: 100%;
    justify-content: center;
}

.myMemeSuggestions-container .MuiGrid-item{
    width: 72%;
    justify-content: center;
}

.memeSuggestion__detail-container-content .div__detail-item{
    margin : 20px 0px;
}

.myMemeSuggestions-container .gridContent__emptyList-message  {
    font-size: clamp(1rem, 1.5vw, 2rem);
    margin: 10px 0px;
}

.myMemeSuggestions-container .gridContent__emptyList-subMessage  {
    font-size: clamp(1rem, 1.5vw, 2rem);
    margin: 10px 0px;
}

