
.mobile .vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-header{
    flex-direction: column;
    width: 100%;
    padding: 20px  0px;
}

.mobile .vendorDashboard__gridContainer--vendorTransactions-header-name{
    width: 90%;
}


.mobile .div__Filters {
    width: 100%;
    margin: 10px 0px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.mobile .vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-content {
    width: 100%;
}

.mobile .div__dateRangePicker-container{
    width: 90%;
    margin: 10px 0px;
}

.mobile .vendorDashboard__gridContainer--vendorTransactions-searchFilter{
    margin-left: 0px;
    margin-top: 10px;
    width: 90%;
}

