.vendorPhoneOTP__formContainer {
    display: flex;
    justify-content: space-around;
}

.vendorPhoneOTP__formContainer--left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;
}

.vendorPhoneOTP__formContainer--left-verifyPhone-header {
    font-size: clamp(1rem, 2.5vw, 2.1rem);
    color: white;
}

.vendorPhoneOTP__formContainer--left-verifyPhone-subHeader {
    font-size: clamp(1rem, 1.5vw, 1.3rem);
    color: #e0e0e0;
}

.vendorPhoneOTP__formContainer--left-verifyPhone-container {
    display: flex;
    justify-content: space-around;
}

.vendorPhoneOTP__formContainer--left-verifyPhone-container .div__countryCodeAutoComplete{
    width: 30%;
}

.vendorPhoneOTP__formContainer--left-verifyPhone-container .div__countryCodeAutoComplete .lblInput {
    font-size: clamp(1rem,1.5vw,1.5rem);
}

.vendorPhoneOTP__formContainer--left-verifyPhone-container .div__txtInput {
    flex: 0.8;
}

.vendorPhoneOTP__formContainer--left-verifyOTP-header {
    font-size: clamp(1.3rem, 1.8vw,2rem);
}

.vendorPhoneOTP__formContainer--left-verifyPhone {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 300px;
}

.vendorPhoneOTP__formContainer--left-OTPMessage,
.vendorPhoneOTP__formContainer--left-phoneNumber {
    font-size: clamp(1rem,1.1vw, 1.6rem);
    color: #e0e0e0;
}

.vendorPhoneOTP__formContainer--left-verifyPhone .div__countryCodeAutoComplete .MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 2px;
    width: 105px;
}

.vendorPhoneOTP__formContainer--left-changePhone {
    color: #86c232;
    margin-left: 10px;
    cursor: pointer;
}

.imgCountryFlag{
    margin-left: 5px;
    width: 20px;
}

.vendorPhoneOTP__formContainer--left-verifyOTP {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 300px;
}

.vendorPhoneOTP__formContainer--left-resendOTP {
    margin-top: 10px;
    font-size: clamp(1rem,1.1vw,1.2rem);
    color: white;
    text-align: center;
}

.vendorPhoneOTP__formContainer--left-resendOTP-timeout {
    margin-left: 10px;
    color: #86c232;
}

.vendorPhoneOTP__formContainer--left-resendOTP-action {
    font-size: clamp(1rem,1.1vw,2rem);
    color: #86c232;
    text-align: center;
    cursor: pointer;
}

#btnInput.vendorPhoneOTP__formContainer--left-verifyOTP {
    width: 70%;
    height: 62px;
    font-size: clamp(1.2rem, 1vw, 2rem);
    text-transform: uppercase;
}

.vendorPhoneOTP__formContainer--right {
    width: 25%;
    aspect-ratio: 238/477;
}







