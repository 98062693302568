.toggle-switch {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 114px;
    height: 46px;
}

.checkbox {
    display: none;
    height: fit-content;
}

.label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #bbb;
    border-radius: 23px;
}

.inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.inner:before, .inner:after {
    float: left;
    width: 50%;
    height: 46px;
    padding: 0;
    line-height: 45px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}

.inner:before {
    content: attr(inactivelabel);
    padding-left: 10px;
    background-color: white;
    color: #707070;
}

.inner:after {
    content: attr(activelabel);
    padding-right: 10px;
    background-color: white;
    color: #707070;
    text-align: right;
}

.switch {
    display: flex;
    width: 36px;
    height: 36px;
    background: black;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 56%;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.checkbox:checked + .label .inner {
    margin-left: 0;
}

.checkbox:checked + .label .switch {
    right: 0px;
}