.admin__ticketsList {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.div__searchInput, .div__Filters{
    display: flex;
}

.div__Filters{
    width: 60%;
}


.div__searchInput{
    width: 40%;
}

.admin__ticketsList-header,
.admin__ticketsList-header svg {
    font-size: clamp(2rem, 2vw, 2.5rem);
    vertical-align: middle;
    margin: 18px 0px;
}

.admin__ticketsList__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.admin__ticketsList__searchInput {
    width: 100%;
    height: 45px;
}

.admin__ticketsList__searchInput input {
    font-size: clamp(1rem, 1.2vw, 1.8rem);
    padding: 7px;
    border-radius: 2px;
}

.admin__ticketsList__fromDate .MuiOutlinedInput-root.MuiInputBase-root,
.admin__ticketsList__toDate .MuiOutlinedInput-root.MuiInputBase-root {
    background-color: #212628;
    color: white;
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    width: 100%;
    height: 45px;
}

.div__dropdown-input .admin__ticketsList__status.MuiOutlinedInput-root.MuiInputBase-root,
.div__dropdown-input .admin__ticketsList__category.MuiOutlinedInput-root.MuiInputBase-root {
    background: #212628;
    color: white;
    width: 100%;
    height: 45px;
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    text-align: left;
}

.admin__ticketsList__status .MuiSelect-icon.MuiSelect-iconOutlined,
.admin__ticketsList__category .MuiSelect-icon.MuiSelect-iconOutlined {
    color: white;
}

.admin__ticketsList__fromDate svg,
.admin__ticketsList__toDate svg {
    color: white;
}

.MuiList-root {
    background-color: #212628;
    color: white;
}

.MuiList-root li {
    font-size: 24px;
}

.MuiDrawer-root.admin__ticketsList__ticketDrawer--view .MuiDrawer-paper {
    height: 85%;
}

.MuiDrawer-root.admin__ticketsList__ticketDrawer--view .btnCloseDrawer svg {
    color: #707070;
}

.div__dateRangePicker-container{
    width: 30%;
    padding: 0px 10px;
}


.div__dropdown-input{
    width: 30%;
    padding: 0px 10px;
}

.div_actionIcons{
    display: flex;
    justify-content: center;
}
