.tabletLandscape .profile-page__gallery-card__container {
    width: 353px;
    height: 421px;
}

.tabletPortrait .profile-page__gallery-card__container {
    width: 353px;
    height: 421px;
}

.tabletPortrait .profile-page__banner-overlay .MuiAvatar-circular.MuiAvatar-root,
.tabletLandscape .profile-page__banner-overlay .MuiAvatar-circular.MuiAvatar-root{
    width: 130px;
    height: 130px;
    bottom: -30%;
}

.tabletPortrait .profile-page__banner-container,
.tabletLandscape .profile-page__banner-container {
    height: 386px;
}

.tabletLandscape .profile-page__gallery-card__container,
.tabletPortrait .profile-page__gallery-card__container{
    margin: 41px 0px 0px 18px !important;
}