.div__staticTableList-container, .listStaticTable {
    width: 100%;
}

.listStaticTable {
    width: 100%;
    border: 1px solid #707070;
    border-collapse: collapse;
}

.listStaticTable th.headerColumn, tr.dataRow td, .noDataRow {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    padding: 15px;
}

.listStaticTable .headerRow {
    border-bottom: 1px solid #707070;
}

.listStaticTable tr.dataRow td {
    padding: 15px;
    text-align: center;
}

.listStaticTable svg.listIcon {
    color: #86c232;
    font-size: 25px;
}

.listStaticTable tbody .loadingProgress {
    color: white;
    padding: 15px;
}

.listStaticTable .div__noData-message {
    padding: 10px;
}

.listStaticTable .messageTitle {
    font-size: 28px;
}

.listStaticTable .messageDescription {
    font-size: 20px;
    width: 30%;
    align-self: center;
    margin: auto;
    margin-top: 10px;
}
