.div__colorSelector-container {
    display: flex;
    flex-direction: column;
}

.div__colorSelector-sizes {
    display: flex;
    margin: 0px 10px;
}

.lblSelectColor {
    color: white;
    margin: 5px 10px;
    font-size: clamp(1rem,1.5vw,1.5rem);
}

.colorOption, .colorOptionSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    border: 1px solid white;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    cursor: pointer;
}

.colorOptionSelected {
    border: 5px solid #86c232;
    padding: 1px;
    width: 34px;
    height: 34px;
}