.myOrders__container {
  padding: 4% 15%;
}

#myOrders__header-iconShoppingBag {
  font-size: 40px;
  color: #c1c1c1;
  margin-bottom: -5px;
}

.myOrders__header {
  display: flex;
  align-items: center;
  color: white;
  font-size: 44px;
}

.myOrders__header-text {
  margin: 15px 0px;
  font-size: clamp(1rem, 4vh, 2rem);
}

.myOrders__container .MuiTabs-root {
  border: none;
}

.myOrders__container .MuiTab-root.MuiTab-textColorPrimary {
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
}

.myOrdersList__overview,.myOrdersList__overview .MuiGrid-item {
  width: 100%;
}

.myOrder__card .div__detail-item-content{
  padding: 10px 0px;
}
