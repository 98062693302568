.tabletPortrait .div__addProductForm-container {
    flex-direction: column;
    width: 100%;
}

.tabletPortrait .div__addProductForm-primary,
.tabletPortrait .div__addProductForm-secondary,
.tabletLandscape .div__addProductForm-primary,
.tabletLandscape .div__addProductForm-secondary {
    width: 97.5%;
    margin : 0px 10px ;
    padding: 0px;
}

.tabletPortrait .div__addProductForm-inventoryList,
.tabletLandscape .div__addProductForm-inventoryList,
.tabletPortrait .div__addProductForm-actions,
.tabletLandscape .div__addProductForm-actions {
    width: 97.5%;
    margin: 20px 10px;
}