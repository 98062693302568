.checkout__mainLayout__Container{
    width: 60%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    margin: 0px auto;
}

.checkout__mainLayout_left{
    width: 40%;
}

.checkout__mainLayout_right{
    width: 60%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
}

.checkout__mainLayout_left .MuiTabs-root {
    width: 100%;
    height: 100%;
}

.checkout__mainLayout_left  .MuiTabs-root .MuiTab-textColorInherit {
    height: 100px;
    font-size: clamp(0.6rem, 1.6vw, 1.3rem);
}

.checkout__mainLayout_left .MuiTabs-flexContainer {
    height: 100%;
}

.checkout__mainLayout__Container .MuiTabs-indicator{
    display: none;
}

.checkout__mainLayout__Container .MuiTab-textColorInherit.Mui-selected {
    background: #222629;
    color: white;
}

.checkout__header{
    display: flex;
    width: 100%;
    align-items: center;
}

.checkout__header__logo{
    width: 8%;
    aspect-ratio: 1/1;
    max-width: 60px;
}

.checkout__header__text{
    padding: 20px;
    font-size: clamp(1.2rem,1.5vw,2rem) ;
}

.checkout_Order-Details{
    display: flex;
    margin: 15px 0px;
    width: 100%;
    justify-content: space-between;
}

.checkout_Order-Details .checkout__Order-Id,
.checkout_Order-Details .checkout_Order-Date
{
    color: #222629 ;
    font-size: clamp(0.8rem,1vw,2rem);
}
