.tabletLandscape .admin__ordersList__filters{
    flex-direction: column;
}

.tabletPortrait .admin__ordersList__filters{
    flex-direction: column;
}

.tabletLandscape .div__searchInput{
    width: 100%;
}

.tabletPortrait .div__searchInput{
    width: 100%;
}

.tabletLandscape .div__Filters{
    width: 100%;
} 
.tabletPortrait .div__Filters{
    width: 100%;
}

@media (max-width: 800px) {

    .orderDetail__container-content--left,.orderDetail__container-content--right,
    .orderDetail__container-content--right-orderStatus, .orderDetail__container-content--right-deliveryStatus
    {
        width: 100%;
    }

    .orderDetail__container-content--left{
        align-items: center;
        margin: 0px;
    }

    .orderDetail__container-content--left .orderDetail__content--left-items,
    .orderDetail__container-content--right-top .orderDetail__container-content--right-orderStatus,
    .orderDetail__container-content--right-top .orderDetail__container-content--right-deliveryStatus
    {
        margin-left: 0px;
        margin-top: 10px;
    }
    
    .orderDetail__container .orderDetail__container-content--right-orderStatus {
        width: 100%;
    }

}

@media (max-width: 900px) {
    .orderDetail__container .orderDetail__container-content--right-orderStatus {
        width: 100%;
    }

}
