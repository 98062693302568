.mainLayout {
  display: flex;
  flex-direction: column;
}

.menu,
.menu__top {
  position: fixed;
  z-index: 5;
  width: 100%;
  background-color: #212628;
  justify-content: space-around;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.menu__bottom {
  margin-top: -14px;
  width: 100%;
}

.menu {
  flex: 1;
  flex-direction: column;
}

div.MuiInput-root {
  height: -webkit-fill-available;
  margin: 0px 5px;
  border-bottom: none;
}

.MuiOutlinedInput-root {
  outline: none;
}

.MuiInput-root:after {
  border-bottom: none;
}

.input:focus,
select:focus {
  outline: none;
}

.MuiSelect-icon.MuiSelect-iconOutlined {
  color: white;
}

.img__Logo {
  width: 70px;
  height: 70px;
  margin: 5px;
  object-fit: contain;
}

.menu__logo {
  display: flex;
  flex-direction: row;
}

#iconMenu {
  font-size: 40px;
  align-self: center;
  color: #86c232;
  margin: 0px 20px;
}

.menu__logo a {
  display: inline-block;
  font-size: 20px;
  color: white;
}

.iconMenu {
  align-self: center;
  margin: 10px;
  cursor: pointer;
}

.menu__container-search {
  display: flex;
  flex-direction: row;
  width: 40%;
  background-color: #212628;
  height: 40px;
  border-radius: 8px;
  color: white;
}

.menu__container-search--mobile {
  width: 50%;
}

.menu__container-search--mobile .mobileSearchInput {
  color: white;
  float: right;
}

.menu__container-searchBar {
  display: flex;
  background-color: #212628;
  width: 80%;
  align-self: center;
  margin: 70px auto;
  border: 1px solid white;
  border-radius: 5px;
}

.menu__container-searchBar-search ,
.menu__container-searchBar-clear,
.menu__container-searchBar-hide {
  color: white;
}

.menu__bottom-collapse {
  width: 100%;
}

.menu__bottom {
  display: flex;
  justify-content: center;
  margin: 0px auto;
}


.genderSelect {
  color: white;
  border: 1px solid #b2b2b2;
  padding: 6px;
  width: 120px;
  text-align: center;
  margin: -3px 6px;
}

.genderSelect div,
.genderSelect .MuiSelect-icon.MuiSelect-iconOutlined {
  color: white;
}

#txtSearchTerm {
  background-color: inherit;
  width: 100%;
  color: white;
  font-size: large;
  border-color: none;
  margin: 0px 5px;
}

.menu__container-search .iconSearch {
  color: white;
}

.menu__container-searchTerm input,
.menu__container-searchTerm input:focus {
  background: #212628;
  border: none;
  color: floralwhite;
}

#iconCart {
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.menu__links {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 5px;
}

.menu__links a {
  margin: 0px 10px;
  font-weight: normal;
  text-decoration: none;
  color: white;
}

.menu__profileLink {
  display: flex;
  align-items: center;
}

.menu__profileLink-userName {
  margin-left: 5px;
}

.iconSearch {
  color: rgb(36, 36, 36);
}

.lblUsername {
  font-size: (0.8rem, 2vw, 2rem);
  text-align: center;
  margin-left: 5px;
}
.lblMyOrder {
  font-size: (0.8rem, 2vw, 2rem);
  text-align: center;
}
.menu .menu__links ::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
}

.menu .menu__links :hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.mobile .menu {
  height: 65px;
}

.mobile .menu.menu__expanded {
  height: 120px;
}

.mobile .menu__profileLink-userName {
  display: none;
}

.mobile .menu__links-myOrders,
.tabletPortrait .menu__links-myOrders {
  display: none;
}

.mobile .img__Logo {
  height: 63px;
  width: 86px;
}

.mobile #iconMenu {
  margin: 0px;
}
