.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color: #FFF;
    background: rgba(0, 0, 0, 0.7);
    z-index: 800;
}
  
.loadingLogo {
    position: relative;
    margin: auto;
    width: 120px;
    height: 120px;
}