.mobile .vendorPhoneOTP__formContainer{
    flex-direction: column-reverse;
    align-items: center;
}

.mobile .vendorPhoneOTP__formContainer--left {
    width: 100%;
}

.mobile .vendorPhoneOTP__formContainer--left-verifyPhone{
    height: 183px;
}

.mobile .vendorOnboarding__right-content {
    padding: 7px 19px;
    width: 90%;
}

.mobile .vendorPhoneOTP__formContainer--left-verifyOTP{
    height: 220px;
}

.mobile #btnInput.vendorPhoneOTP__formContainer--left-verifyOTP {
    margin-top: 30px;
}