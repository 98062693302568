.div__inventoryList {
    margin: 20px 30px;
}

.div__inventoryList-filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px -8px;
}

.div__inventoryList-tableContainer {
    display: flex;
    justify-content: space-around;
}

.headerInventoryList {
    font-size: 26px;
    align-self: center;
}

.inventorySearchDatePicker div {
    background-color: white;
    border-radius: 10px;
    height: 40px;
}

.div__inventoryList-filters .div__dateRangePicker-container p.lblInput {
    margin: 2px;
}

tr.dataRow td {
    padding: 15px;
}

svg.listIcon {
    color: #86c232;
    font-size: 25px;
}

.inventoryImage {
    width: 150px;
    height: 150px;
}