.div__addInventoryForm {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    flex-wrap: wrap;
}

.div__addInventoryForm-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}

.headerAddInventory {
    text-align: center;
    font-size: clamp(1.5rem, 2vw, 2rem);
    align-self: center;
}

.div__addInventoryForm-primary {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 0px 30px;
    justify-content: space-around;
    width: 40%;
}

.div__addInventoryForm-secondary {
    display: flex;
    width: 55%;
    flex-direction: column;
    justify-content: space-between;
}

.div__addInventoryForm .div__txtInput {
    width: 97%;
    margin: 0px 5px;
}

.div__addInventoryForm-primary-dropdown, .div__addInventoryForm-secondary-dropdown {
    display: flex;
    justify-content: space-between;
}

.div__addInventoryForm-imageUpload {
    display: flex;
    margin: 10px 5px;
    justify-content: center;
}

.div__addInventoryForm-primary-finance {
    display: flex;
}

.selectGender, .selectColor {
    padding: 8px 5px;
    width: 100%;
    background-color: white;
    color: black;
    margin: 1px;
}

.selectGender .MuiSelect-icon.MuiSelect-iconOutlined,
.selectColor .MuiSelect-icon.MuiSelect-iconOutlined { 
    color: black;
}

.selectFabricType {
    margin: 0px 10px;
}

.div__addInventoryForm-imageUpload {
    display: flex;
    overflow: auto;
}

.div__addInventoryForm-videoEmbed {
    display: flex;
}

.div__addInventoryForm-primary-dropdown #dropdownInput{
    height: 50px;
}

.div__addInventoryForm-secondary-dropdown .div__colorPicker input{
    height: 30px;
}

.makeStyles-root-1{
    align-items: center;
}

.inventoryOrderDatePicker {
    background-color: white;
    border-radius: 10px;
    height: 50px;
}

.div__imagePreview-container .div__imagesUploaded {
    height: 150px;
}

.div__addInventoryForm-invoicePreview {
    border-radius: 5px;
    border: 1px solid lightgray;
    height: 150px;
    color: white;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.div__addInventoryForm-invoice {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 115px;
    width: 50%;
}

.lblUpload {
    width: 170px; 
}

.btnUploadInvoice {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #86c232;
    color: white;
    width: 80%;
    padding: 10px 3px;
    text-align: center;
    border-radius: 8px;
    margin: 8px;
    cursor: pointer;
    justify-content: center;
}

.invoiceLink {
    text-decoration: underline;
    color: #86c232;
}

.noInvoice {
    margin: 0px 3px;
}

.deleteInvoice {
    color: red;
}

.div__addInventoryForm-orderDetails {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
}

.div__addInventoryForm-invoice .invoiceUploading {
    margin: auto;
}

.div__addInventoryForm-primary button.btnSubmitInventory {
    width: 250px;
    background-color: #86c232;
    text-transform: none;
    border-color: #86c232;
    color: white;
    font-size: 16px;
    align-self: center;
}

.div__addInventoryForm-container .div__txtInput .lblInput{
    font-size: clamp(1rem,1vw,1.5rem);
}

.div__imagePreview-container .lblInput{
    font-size: clamp(1rem,1vw,1.5rem);
}

.div__addInventoryForm-container .lblInput{

    font-size: clamp(1rem,1vw,1.5rem);

}

.div__addInventoryForm-secondary .div__imagePreview-container, .div__addInventoryForm-secondary .div__imagePreview {
    width: 95%;
}

.div__addInventoryForm-orderDetails .div__dateRangePicker-container{
    width: 52%;
}

.div__addInventoryForm-primary-dropdown .div__dropdown-input{
    width: 30%;
}

.div__addInventoryForm-secondary .imgDetailPreview,.div__addInventoryForm-secondary .imgCardPreview{
    width: 100%;
}

.div__addInventoryForm-secondary .imgDetailPreview{
    height: 450px;
}

.div__addInventoryForm-secondary .imgCardPreview{
    margin: 0px 20px;
}

.div__addInventoryForm-primary-dropdown .div__sizeSelector-container{
    width: 63%;
}


@media (max-width:800px){

    .div__addInventoryForm-container{
        flex-direction:column;
    }
    
    .div__addInventoryForm-primary, .div__addInventoryForm-secondary{
        width: 88%;
    }
    
    .div__addInventoryForm-primary{
        order: 2;
    }

    .div__addInventoryForm-secondary{
        order: 1;
    }

}

@media (max-width:950px) {

    .div__addInventoryForm-primary-dropdown .div__sizeSelector-container
    {
        width: 80%;
    }

    .div__addInventoryForm-primary-dropdown .div__dropdown-input{
        width: 97%;
    }

    .div__addInventoryForm-orderDetails .div__dateRangePicker-container{
        width: 100%;
    }

    .div__addInventoryForm-invoice{
        justify-content: flex-start;
    }

    .div__addInventoryForm-primary{
        padding: 0px;
        width: 100%;
    }

    .div__addInventoryForm-secondary .div__imagePreview-container{
        margin: 0px;
    }

    .div__addInventoryForm-secondary .imgDetailPreview{
        height: 450px;
        border-radius: 15px;
    }

    .addInventoryDrawer  .MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
        height: 100%;
        border-radius: 0px;
        padding: 11px;
    }

    .div__imagePreview {
        flex-direction: column;
    }

    .div__addInventoryForm-secondary .div_displayImage {
        width: 95%;
    }

    .div__addInventoryForm-secondary .imgCardPreview {
        margin: 0px 5px;
    }

}

