.addTicket__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addTicket__form-header {
    color: #585858;
    font-size: clamp(1rem, 1.5vw, 2.5rem);
}

.addTicket__form-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.addTicket__form--left .orderDetail__content--left-items {
    width: 100%;
}

.addTicket__form--left .orderDetail__content--left-item-details,
.addTicket__form--left .orderDetail__content--left-item-header {
    padding: 10px;
}

.addTicket__form--right {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
}

.addTicket__form-selectCategory {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
}

.addTicket__form-selectCategory span {
    margin-right: 20px;
}

.addTicket__form-selectCategory-dropdown {
    width: 170px;
    height: 50px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.addTicket__form-attachImages {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.addTicket__form-attachImages-label {
    color: #585858;
    font-size: 23px;
    text-transform: uppercase;
}

.div__txtInputArea_label .lblInput {
    color: #a7a7a7;
}

#btnInput.addTicket__form-cancel,
#btnInput.addTicket__form-submit {
    width: 375px;
    height: 75px;
    font-size: clamp(1.2rem, 2vw,2rem);
    text-transform: uppercase;
    border-radius: 10px;
}

#btnInput.addTicket__form-cancel {
  border-radius: 10px;
  border: solid 2px #74a730;
  color: #74a730;
  background-color: white;
}

.addTicket__form span.errorMessage {
    color: crimson !important;
}

.addTicket__form-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
}

.addTicket__form--left{
    width: 40%;
}

@media (max-width: 1000px){
    .addTicket__form-container{
        flex-direction: column;
    }
    .addTicket__form--left{
        width: 100%;
    }
    .addTicket__form--right{
        width: 100%;
        margin-top: 30px;
    }
    #btnInput.addTicket__form-cancel,
    #btnInput.addTicket__form-submit{
        height: 70px;
        font-size: 16px;
        width: 35%;
    }
    .addTicket__form-buttons {
        justify-content: space-around;
    }
    .addTicket__form--right {
        padding: 0px 5px;
    }
    .addTicket__form-attachImages-label {
        font-size: 18px;
    }
}