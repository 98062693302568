.div__dropdown-input {
    display: flex;
    flex-direction: column;
}
  
.txtAutoComplete .MuiSelect-icon.MuiSelect-iconOutlined {
    color: black;
    padding: 0px;
}

.txtAutoComplete div.MuiInputBase-root {
    padding: 0px;
}

.lblOption {
    color: black;
}

.div__autoComplete-input .lblInput {
    font-size: clamp(1rem,1.2vw,1.5rem);
}