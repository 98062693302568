.menuDrawer .MuiDrawer-paper {
    width: 269px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.menuDrawer .MuiDrawer-paper::-webkit-scrollbar {
    display: none;
}

.div__menuDrawer-header {
    background-color: #212628;
    color: white;
    font-size: 23px;
    padding: 0px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 75px;
}

.div__menuDrawer-header div,
.div__menuDrawer-header p {
    cursor: pointer;
}

.lblProfileName {
    text-align: center;
    margin: 0px 15px;
    text-overflow: ellipsis;
}

.div__menuDrawer-content {
    display: flex;
    justify-content: space-between;
    background-color: #585858;
    color: #cbcbcb;
    flex: 1;
}

.div__menuDrawer-content .MuiList-root {
    background-color: #585858;
}

.menuIcon {
    color: #cbcbcb;
}



hr.MuiDivider-root {
    border: 0.5px solid #707070;
    width: 90%;
    margin: auto;
}

li.MuiListItem-root.listItemLanguage {
    display: flex;
    flex-direction: column;
}

.div__languagesList {
    display: flex;
    justify-content: space-between;
}

.lblLanguagesHeader {
    color: white;
    font-size: 1rem;
    margin: 5px 30px;
    cursor: pointer;
}

.lblLanguageItem {
    padding: 10px;
    color: #cbcbcb;
    cursor: pointer;
    font-size: 1rem;
}

hr.div__languageDivider {
    width: auto;
}

.div__languagesCollapsible {
    margin-left: -50px;
}

.expandedlanguagesList {
    background-color: #404040;
    padding: 5px;
}