.freechargeLogo {
    aspect-ratio: 592/479;
    width: 50%;
    margin: 0px auto;
}

.payzappLogo {
    aspect-ratio: 860/267;
    width: 100%;
    margin: 0px auto;
}

.amazonpayLogo{
    aspect-ratio:183/74 ;
   width: 100%;
}

.phonepeLogo {
    aspect-ratio: 177/59;
    width: 100%;
}

.otherWallet__dropdown {
    width: 100%;
    margin: 20px 15px;
}

.otherWallet__dropdown .MuiOutlinedInput-root,
.otherWallet__dropdown .MuiFormControl-root {
    width: 80%;
}


.walletCard {
    padding: 20px;
    width: 30%;
    border: solid 2px grey;
    border-radius: 20px;
    margin: 10px;
    align-items: center;
    cursor: pointer;
}

.OtherWallet__form, .netBanking__form--topWallets {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.otherWallet__dropdown .div__dropdown-input {
    width: 100%;
}