.div__productDetail-topContainer, 
.div__productDetail-images,
.div__productDetail-content-rating,
.div__productDetail-content-cartQuantity,
.div__productDetail-content-sizeSelect {
    display: flex;
}

.div__productDetail-topContainer {
    background-color: #2b2b2b;
}

.div__productDetail-imagesList .div-uploadedImage .imageItem {
    width: 90px;
    height: 90px;
    border-radius: 10px;
    object-fit: cover;
}

.div__productDetail-images {
    width: 40%;
    flex: 1.5;
    justify-content: space-evenly;
    flex-direction: column;
}

.div__productDetail-imagesList .div-uploadedImage-selected {
    width: 85px;
}

.div__productDetail-imagePreview .imgDetailPreview {
    width: 150%;
    object-fit: cover;
    margin: 5px;
    border-radius: 17px;
    aspect-ratio: 325/450;
}

.div__productDetail-imagesSection {
    display: flex;
    justify-content: space-evenly;
}

.div__productDetail-imagesList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 15px 0px;
    width: 20%;
    padding: 0px 6%;
}

.div__productDetail-fabricSection {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.div__fabricDescription {
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
}

.fabricTitle {
    font: clamp(1rem,1.2vw,1.5rem);
    color: #c4c4c4;
}

.fabricDescription {
    margin: 10px 0px;
    width: 65%;
}

.fabricSvg {
    height: 30px;
    width: 30px;
    margin: -5px 10px;
}

.div__productDetail-designedBy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -45px;
    width: 140px;
}

.div__productDetail-designedBy .MuiAvatar-root.MuiAvatar-circular {
    width: 35px;
    height: 35px;
}

.lblProductDesignedBy {
    margin: 0px 10px;
}

.visitProfile, .visitProfile:hover {
    color: #86c232;
    text-decoration: none;
}

.div__productDetail-content {
    width: 50%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    flex: 2;
    word-wrap: normal;
    margin-top: 15px;
}

.div__productDetail-content-cartOptions {
    display: flex;
    flex-direction: column;
}

.div__productDetail-content-rating, .productDetail, .productPunDescription {
    margin: 12px 0px;
}

.productDetail {
    color: #808080;
    font-size: clamp(1rem,1.2vw,1.2rem);
}

.productPunDescription, .deliveryInstruction {
    color: #c4c4c4;
    font-size: clamp(1rem,1.2vw,1.2rem);
    width: 60%;
}

.MuiSelect-icon.MuiSelect-iconOutlined {
    color: black;
    margin-left: 15px;
}

.txtCartQuantity input, .txtDeliveryCode input {
    height: 16px;
    background: white;
    border-radius: 8px;
    padding: 8px;
    margin: 5px -5px;
}

.txtCartQuantity input {
    margin-top: -5px;
    width: 60px;
}

.dropdownCartQuantity {
    margin: -5px 5px;
    background-color: white;
    border-radius: 8px;
    width: 60px;
    height: 25px;
}

.div__productDetail-priceDetails {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div__productDetail-content-cartQuantity {
    height: 30px;
    margin: 8px 0px;
}

.div__productDetail-content-sizeSelect .lblSelectSize {
    margin: 10px 0px;
    color: #a0a0a0
}

.lnkSizeChart {
    height: 20px;
    color: #86c232;
    margin: 8px -98px;
    font-size: clamp(1rem,1.2vw,1.5rem);
    cursor: pointer;
}

span.MuiRating-iconEmpty {
    color: white;
}

.div__productDetail-delivery {
    flex: 1;
    padding: 10px;
}

.div__productDetail-delivery p {
    margin: 10px 0px;
}

svg.localShippingIcon {
    margin: -8px 4px;
}

.div__productDetail-inventoryCart {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #fff;
    color: black;
    padding: 10px;
    opacity: 0.9;
    margin-bottom: -50px;
    width: 120%;
}

.discountedPrice, .actualPrice, .discount {
    text-align: center;
}

.discountedPrice {
    font-size: clamp(2rem, 3.2vw , 6rem);
    color: #545454;
}

.actualPrice {
    text-decoration: line-through;
    color: #707070;
    margin: 3px;
    font-size: 18px;
}

.discount {
    color: #707070;
    font-size: 15px;
}

.div__productDetail-priceColorGender {
    display: flex;
    justify-content: space-around;
}

.div_productDetail-selectColor .lblSelectColor {
    color: #545454;
    margin: 8px 17px;
    font-size: clamp(1rem, 1.2vw, 2rem);
}

.div__productDetail-cartActions {
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;
}

button.btnWishlist, button.btnAddToCart, button.btnBuyNow  {
    text-transform: none;
    display: flex;
    justify-content: space-evenly;
    width: 33%;
    margin: 10px;
    font-size: clamp(.8rem, 1vw, 1.2rem);
}

button.btnWishlist, button.btnWishlist:hover {
  color: #86c232;
  background-color: white;
  opacity: 1;
  border: 2px solid #86c232;
  justify-content: center;
}

button.btnAddedToCart, button.btnAddedToCart:hover {
    background-color: #86c232;
    color: white
}

button.btnAddToCart, button.btnAddToCart:hover {
    background-color: transparent;
    border: 2px solid #86c232;
    color: #86c232;
}

button.btnAddToCart:disabled {
    opacity: 0.5;
}

button.btnBuyNow, button.btnBuyNow:hover {
    color: white;
    background-color: #86c232;
}

.lblOutOfStock {
    color: crimson;
    font-size: 32px;
    align-self: center;
}

button.btnBuyNow:disabled {
    background-color: grey;
}

button.btnBuyNow:disabled svg.iconBuyNow {
    display: none;
}

.sizeChartDrawer.MuiDrawer-root {
    background-color: white;
    top: 30%;
}

.sizeChartDrawer .MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
    height: auto;
}

.sizeChartDrawer .btnCloseDrawer svg {
    color: grey;
}

.div__snackbar-cart {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.div__snackbar-cart .iconCart {
    margin: 0px 10px;
}

.productTitle{
    font-size: clamp(1.2rem, 1.8vw, 1.7rem);
}

.div__productDetail-imagePreview{
    width: 70%;
}

.div__sizeChart-content-header{
    align-items: center;
    margin: 20px 0px;
}

button.btnAddToCart, button.btnBuyNow {
    width: 33%;
    justify-content: center;
    height: 60px;
}

.iconAddToWishlist, .iconAddToCart, .iconBuyNow{
    margin: 0px 5px;
}