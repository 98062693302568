.div__txtInputArea {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
}

.div__txtInputArea input {
    border-radius: 10px;
    color: black;
    background-color: white;
    padding: 0.5em;
}

.div__txtInputArea_label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div__txtInputArea .lblInput {
    font-size: clamp(1rem,1.5vw,1.5rem);
    color: white;
}

.txtAreaInput {
    border-radius: 10px;
    font-size: 16px;
    font-family: Helvetica;
    padding: 5px;
}