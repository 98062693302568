.div__selectGender-options {
    display: flex;
    justify-content: space-around;
}

.div__selectGender-options button {
    padding: 0px;
}

.lblSelectGender {
    color: #545454;
    font-size: clamp(1rem, 1.2vw, 2rem);
    margin: 5px;
}

.lblGender {
    color: #545454;
    font-size: clamp(1rem, 1.2vw, 2rem);
    text-align: center;
    margin-top: 5px;
}

.lblGender.lblGenderSelected {
    color: #86c232;
}

.genderOption {
    height: 43px;
    width: 43px;
}

.genderOptionSelected {
    border: 3px solid #86c232;
    border-radius: 50%;
    margin: -2px;
}

.div__selectGender-options button:disabled  {
    opacity: 0.3;
    cursor: not-allowed;
}