.analytics__card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px;
    justify-content: space-around;
    background-color: #212628;
}

.div__detail-item-content.analytics__card-content {
    padding: 0px 13px;
    height: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
}

.div__detail-item.analytics__card {
    height: 230px;
    width: 410px;
    border-radius: 8px;
}

.analytics__card--left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.analytics__card--left-header,
.analytics__card--left-content,
.analytics__card--left-subContent {
    color: #cbcbcb;
}

.analytics__card--left-header {
    font-size: clamp(1rem,1.5vw,1.5rem);
}

.analytics__card--left-subContent {
    font-size: clamp(0.9rem,0.9vw,1.2rem);
}

.analytics__card--left-content {
    font-size: clamp(1.5rem,2vw,2rem);
    padding: 10px 0px;
}

.analytics__card--right {
    font-size: clamp(1rem,1.5vw,1.5rem);
    color: #cbcbcb;
    display: flex;
    align-items: center;
}

.analytics__card--right .analytics__card--right-increment,
.analytics__card--right .analytics__card--right-decrement {
    font-size: 33px;
    vertical-align: middle;
}

.analytics__card--right .analytics__card--right-increment {
    color: #74a730;
}

.analytics__card--right .analytics__card--right-decrement {
    color: #d40000;
}

.analytics__card .analytics__card--right-accountBalance {
    width: 48px;
    font-size: 62px;
    color: #cbcbcb
}

.analytics__card .div__detail-item {
    margin: 0px;
    border: 0px;
}