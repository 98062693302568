.div__colorPicker {
    display: flex;
    flex-direction: column;
}

.div__colorPicker button {
    border: 16px solid lightgray;
}

.div__colorPicker .lblInput {
    font-size: clamp(1rem,1.5vw,1.5rem)
}

.ColorPicker-MuiInput-underline:before,
.ColorPicker-MuiInput-underline:before {
    border-bottom: 0px;
}

.div__colorPicker input {
    background-color: white;
    border-radius: 8px;
    color: black;
    padding: 5px;
    margin: 8px 0px;
}