.mobile .imgLogo {
  width: 139px;
  height: 122px;
  align-self: center;
}
.mobile .div__login-formContainer {
  width: 100%;
  height: 100%;
  margin: 0px;
  border-radius: 0px;
  border: none
}
.mobile .div__login-form {
  margin-left: 40px;
}
.lnkLogo {
  width: 100%;
  height: 170px;
  align-self: center;
}

.mobile .lnkLogo {
  width: 100%;
  height: 140px;
  align-self: center;
}

.mobile #btnInput.btnSignIn,
.mobile #btnInput.btnRegister {
  width: 235px;
  height: 45px;
  font-size: 18px;
}

.mobile .div__login-form-googleLogin,
.mobile .div__login-form-facebookLogin {
  width: 280px;
  height: 40px;
  background: #4285f4;
  color: white;
  display: flex;
  font-size: 18px;
  cursor: pointer;
}
.mobile .appContainer {
  margin-top: 0px;
}

.mobile .routesContainer {
  padding: 0
}
