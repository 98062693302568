.div__productDetail-memePreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 75px 0px;
    width: 100%;
}

.memeVideoPreview {
    width: 100%;
    aspect-ratio: 16/9;
    background: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.memeVideoPreview .playPreviewIcon {
    font-size: 100px;
    color: #86c232;
}

.videoPreview{
    display: flex;
    justify-content: center;
    width: 90%;
}