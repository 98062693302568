@media (max-width : 800px){
    .memeSuggestionDetail-container__view {
        flex-direction: column;
    }

    .memeSuggestionDetail-container__leftGallery{
        width: 100%;
    }
    .memeSuggestionDetail-container__rightGallery{
        width: 100%;
    }
} 