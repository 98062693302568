.choiceQuestion__container {
    width: 33%;
}

.choiceQuestion__question {
    color: #585858;
    font-size: clamp(1rem, 2vw,1.2rem);
    text-align: center;
    margin-bottom: 15px;
}

.choiceQuestion__optionsList {
    display: flex;
    justify-content: space-around;
    padding: 0px 15%;
}

.choiceQuestion__optionsList .choiceQuestion__option,
.choiceQuestion__optionsList .choiceQuestion__option:hover {
    width: clamp(70px,8vw,100px);
    height: 50px;
    border: 3px solid #85c340;
    border-radius: 10px;
    font-size: clamp(1rem,2vw,2rem);
    color: #585858;
    text-transform: none;
}

.choiceQuestion__optionsList .choiceQuestion__optionSelected,
.choiceQuestion__optionsList .choiceQuestion__optionSelected:hover {
    background-color: #ecffd6;
}

@media (max-width:675px){
    .choiceQuestion__container{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .choiceQuestion__optionsList{
        padding: 0px;
        width: 50%;
    }
    .choiceQuestion__question{
        width: 50%;
    }

}