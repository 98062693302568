.tabletLandscape .img__registerLogo {
  width: 270px;
  height: 240px;
  margin: auto;
}

.tabletPortrait .img__registerLogo {
  width: 200px;
  height: 200px;
  margin: auto;
}
