.div__itemSelected {
    display: flex;
    justify-content: space-between;
}

.div__itemSelected-content {
    display: flex;
}

.div__itemSelected-details {
    margin: 0px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #808080
}

.div__itemSelected .div__productDetail-priceDetails .discountedPrice{
    font-size: clamp(1rem,1.5vw,2rem);
}

.div__itemSelected-content .div-uploadedImage .imageItem {
    width: 100px;
    height: 100px;
    border-radius: 25px;
    object-fit: cover;
}

.lblItemTitle {
    font-size: 20px;
}

.lblItemLanguage {
    font-size: 18px;
}

.div__itemSelected-rating {
    display: flex;
}

.div__itemSelected-rating span.MuiRating-iconEmpty {
    color: #707070;
}