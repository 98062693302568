.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
    height: 94%;
    border-radius: 10px;
    padding: 20px;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorBottom::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.btnCloseDrawer {
    width: 40px;
    font-size: 40px;
    align-self: flex-end;
    padding: 0px;
}

.btnCloseDrawer svg {
    color: white;
    font-size: 30px;
}

@media (max-width:600px) {
    
    .MuiDrawer-root.admin__ticketsList__ticketDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
        padding: 15px;
    }

    .MuiDrawer-paper .btnCloseDrawer{
        margin-top: 20px;
    }
}