.imgCountryFlag {
    margin-left: 10px;
    width: 25px;
}

.div__countryCodeAutoComplete {
    display: flex;
    flex-direction: column
}

.div__countryCodeAutoComplete .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 2px;
}

.div__countryCodeAutoComplete .MuiOutlinedInput-root .MuiAutocomplete-hasPopupIcon{
    padding-right: 0px;
}