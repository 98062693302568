.div__searchProducts-container,
.div__searchProducts-filters-header,
.div__searchProducts-header,
.div__searchProducts-header-left {
    display: flex;
}

.div__searchProducts-container-drawerOpen {
    margin-left: 24%;
}

.div__searchProducts-filters {
    flex: 0.2;
}

.div__searchProducts-filters-header {
    justify-content: space-between;
}

.lblFilters {
    font-size: 22px;
}

.div__searchProducts-filters .btnApplyFilters,
.div__searchProducts-filters .btnApplyFilters:hover {
    background-color: #86c232;
    color: white;
    padding: 5px 20px;
    margin-right: 50px;
    text-transform: none;
}

.div__searchProducts-header {
    justify-content: space-between;
}

.div__searchProducts-header-left {
    display: flex;
    align-items: center;
}

.div__searchProducts-header .iconFilters,
.div__searchProducts-header .iconSort {
    color: #86c232;
    font-size: clamp(1.5rem,2vw,2rem);
}

.div__searchProducts-header .iconSort {
    align-self: flex-end;
}

#sortMenu .MuiMenu-paper {
    background-color: #212628;
    color: white;
    width: 296px;
}

.lblSearchResults {
    color: white;
    font-size: clamp(1rem,1.5vw,2rem);
    display: inline-block;
    align-self: center;
}

.drawer__searchFilters .MuiPaper-root.MuiDrawer-paper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.drawer__searchFilters .MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar {
    display: none;
}

.drawer__searchFilters .MuiPaper-root.MuiDrawer-paper {
    background-color: #585858;
}

.div__searchProducts-results {
    padding: 10px;
    width: 100%;
}

.div__searchProducts-results-list {
    width: 100%;
    height: 100%;
    display: flex;
}

.div__searchProducts-results-list .loadingProgress {
    color: white;
    margin: 25% auto;
    width: 50px;
    height: 50px;
}

.lblSortMenuHeader {
    padding: 15px;
    font-size: 25px
}

.div__searchProducts-sortMenu-option {
    display: flex;
    justify-content: space-between;
    font-size: clamp(0.8rem, 1.2vw,1.5rem);
    color: #d2d2d2;
    width: 100%;
}