.mobile .vendorOnboarding__basicDetails-formContainer{
    padding: 0px;
}

.mobile .div__dropdown-input{
    width: 100%;
}

.mobile .vendorOnboarding__basicDetails-formContainer-image {
    flex-direction: column-reverse;
}

.mobile .vendorOnboarding__basicDetails-formContainer-image .lblUpload {
    width: 100%;
    margin-top: 15px;
}

.mobile .vendorOnboarding__basicDetails-formContainer-image--right {
    width: 70%;
}

.mobile .vendorOnboarding__basicDetails-formContainer-details,
.mobile .vendorOnboarding__basicDetails-formContainer-companyDetails,
.mobile .vendorOnboarding__basicDetails-formContainer-addressLine,
.mobile .vendorOnboarding__basicDetails-formContainer-addressLocation {
    flex-direction: column;
    align-items: center;
}

.mobile .vendorOnboarding__basicDetails-formContainer-addressLine,
.mobile .vendorOnboarding__basicDetails-formContainer-addressLocation,
.mobile .vendorOnboarding__basicDetails-formContainer-heardAboutUsText {
    width: 95%;
}

.mobile .vendorOnboarding__basicDetails-formContainer-addressLine .div__txtInput,
.mobile .vendorOnboarding__basicDetails-formContainer-addressLocation .div__txtInput,
.mobile .vendorOnboarding__basicDetails-formContainer-addressLocation .div__autoComplete-input {
    width: 100%;
}

.mobile #btnInput.vendorOnboarding__basicDetails-formContainer-submit {
    width: 330px;
}