.div__unauthorizedPage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 55%;
    margin: auto;
}

.imgUnauthorizedPage {
    aspect-ratio: 760/550;
    width: 90%;
}

.imgPageNotFound{
    aspect-ratio: 139/98;
    width: 90%;
}

.lblUnauthorizedHeader,
.lblUnauthorizedMessage,
.lblUnauthorizedMessage2 {
    color: #cbcbcb;
    text-align: center;
}

.lblUnauthorizedHeader,
.lblUnauthorizedMessage {
    margin-top: 25px;
}

.lblUnauthorizedHeader {
    font-size: clamp(1.5rem,1.8vw,2rem);
}

.lblUnauthorizedMessage,
.lblUnauthorizedMessage2 {
    font-size: clamp(1rem,1vw,1.5rem);
}

.lblHelpEmail {
    color: white;
    font-weight: bold;
    margin-left: 5px;
}

#btnInput.btnGoBack {
    background-color: #74a730;
    color: white;
    width: 80%;
    max-width: 300px;
    margin-top: 15px;
    font-size: clamp(1rem,1.2vw,2rem);
    font-weight: bold;
}

