.mobile .vendorAgreement__form--top{
 flex-direction: column-reverse;   
 width: 85%;
}

.mobile .vendorAgreement__form--top--left{
    text-align: center;
}

.mobile .vendorAgreement__form--top--right{
    margin: 20px 0px;
    width: 70%;
    aspect-ratio: 312/187;
}

.mobile .vendorAgreement__form-termsAndConditions{
    margin: 0px;
}

.mobile #btnInput.vendorAgreement__form-agreeTerms,
.mobile .vendorAgreement__form .vendorAgreement__form-declineTerms {
    font-size: clamp(1.2rem, 1.8vw, 2rem);
    width: 80%;
    height: 40px;
}

.mobile .vendorAgreement__form{
    align-items: center;
}

.mobile .vendorAgreement__form-success-message {
    width: 90%;
}