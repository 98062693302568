.tabletPortrait .vendorDashboard__gridContainer {
    display: flex;
    flex-direction: column;
}
.tabletPortrait .vendorDashboard__gridContainer--dispatchOrders {
    width: 98%;
}
.tabletPortrait .vendorDashboard__gridContainer-dispatchOrders {
    max-width: 100%;   
}

.tabletPortrait .vendorDashboard__gridContainer-analytics {
    flex-wrap: wrap;
    max-width: 100%;
}

.tabletPortrait .vendorDashboard__gridContainer-latestTransactions,
.tabletPortrait .vendorDashboard__gridContainer-vendorBankAccounts {
    max-width: 100%;
}