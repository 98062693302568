.div__imagePreview-container {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
}

.div__imagesUploaded {
    display: flex;
    justify-content: flex-start;
    height: 220px;
}

.div__imagesUploaded .loadingProgress {
    margin: auto;
}

.div-uploadedImage .imageItem {
    width: 140px;
    height: 130px;
    border-radius: 25px;
    object-fit: cover;
}

.imageLoading {
    margin: auto
}

.div__imagePreview {
    display: flex;
    margin: 10px;
    text-align: center;
}

.imgDetailPreview, .imgCardPreview {
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: lightgray;
    font-size: 20px;
}

.imgDetailPreview {
    height: 373px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.imgCardPreview {
    height: 275px;
    width: 275px;
    object-fit: cover;
    margin: 5px;
}

.div__uploadFile {
    width: 165px;
    margin-left: auto;
    margin-top: 30px;
}

.btnUploadMedia {
    background-color: rgba(173, 255, 47, 0.75);
    color: white;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    padding: 13px;
    display: flex;
}

.btnUploadMedia span {
    margin: 3px;
}

.div-uploadNew {
    background-color: #aeaeae;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div__editableImage {
    display: flex;
    align-items: center;
    flex-direction: column;
}