
.mobile .admin__memeSuggestionsList__filters{
    flex-direction: column;
}

.mobile .div_Filters{
    width: 109%;
    margin: 10px 0px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.mobile .div__searchInput{
    width: 98%;
}

.mobile .admin__memeSuggestionsList{
    padding: 10px;
}

.mobile .admin__memeSuggestionsList__filters .div_Filters .div__dateRangePicker-container,
.mobile .admin__memeSuggestionsList__filters .div__dropdown-input{
    width: 40%;
    padding: 0px 10px;
    font-size: clamp(1rem,1vw,1.5rem);
    margin: 5px 0px;
}
