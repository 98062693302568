.productFeedback__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
}

.productFeedback__form-header {
    color: #585858;
    font-size: clamp(1.4rem,1.6vw,3rem);
}

.productFeedback__form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.productFeedback__form--leftRight {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.productFeedback__form--left{
    width: 30%;
}

.productFeedback__form--left .orderDetail__content--left-item-details,
.productFeedback__form--left .orderDetail__content--left-item-header {
    padding: 10px;
}

.productFeedback__form--left-image {
    width: 430px;
    height: 350px;
}

.productFeedback__form--right {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    height: 100%;
}

.productFeedback__form-rating {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.productFeedback__form-rating-header {
    font-size: clamp(1rem,1.5vw,2rem);
    color: #585858;
    margin: 10px 0px;
}

.productFeedback__form-rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
}

.productFeedback__form-rating-container svg {
    color: #d3d3d3;
    font-size: clamp(2.5rem,3vw,4rem);
}

.productFeedback__form-rating-container svg.MuiRating-iconFilled {
    color: #86c232;
    font-size: 65px;
}

.productFeedback__form-images .productFeedback__form-images-header {
    padding: 10px;
}

.productFeedback__form-rating-label {
    text-align: center;
    font-size: clamp(1rem,1.3vw,1.8rem);
    color: #d3d3d3;
}

.productFeedback__form-rating-error {
    color: #585858;
    font-size: 22px;
    margin-top: 20px;
    color: crimson;
}

.productFeedback__form--right .productFeedback__form-images {
    width: 100%;
}

.productFeedback__form-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    width: 100%;
}

.productFeedback__form .productFeedback__form-descriptionInput {
    margin: 20px 0px;
    width: 100%;
}

.productFeedback__form--left .orderDetail__content--left-items{
    margin: 0px;
}

.productFeedback__form-descriptionInput input {
    font-size: clamp(1rem,1.5rem,2rem);
    width: 100%;
}

.productFeedback__form-description-label {
    text-align: center;
    margin: 10px 0px;
    color: #d3d3d3;
    font-size: clamp(1rem,1.2rem,2rem);
}

#btnInput.productFeedback__form-submit {
    width: 50%;
    height: 65px;
    font-size: clamp(1.2rem,1.5vw,2rem);
    text-transform: uppercase;
    border-radius: 10px;
}

.productFeedback__form span.errorMessage {
    color: crimson !important;
}

.productFeedback__form-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media (max-width: 960px){
    .productFeedback__form--leftRight{
        flex-direction: column;
    }
    
    .productFeedback__form--left,
    .productFeedback__form--right{
        width: 100%;
        margin: 20px 0px;
    }

    .productFeedback__form--right{
        padding: 0px;
    }

    .productFeedback__form-description{
        margin-top : 20px;
    }
}

@media (max-width:640px) {
    #btnInput.productFeedback__form-submit{
        width: 100%;
    }

    .productFeedback__form{
        padding: 0px;
    }

    .productFeedback__form-descriptionInput input{
        font-size: 16px;
    }

    .div__imagesUploaded .div-uploadedImage {
        display: flex;
        flex-direction: column;
        width: 107px;
        height: 102px;
        border-radius: 14px;
        padding: 4px;
    }

    .div__imagePreview-container .div__imagesUploaded{
        height: 130px;
    }
    
    
    
}