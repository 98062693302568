.mobile .myTickets__ticketCard-content-details-titleDescription,
.myTickets__ticketCard-content-details-replyDescription {
  font-size: clamp(1.1rem, 1.2vh, 2rem);
}

.mobile  .myTickets__ticketCard-content-details-titleLabel,
.myTickets__ticketCard-content-details-replyLabel {
  font-size: clamp(0.9rem, 1vh,2rem);
  color: #b7b7b7;
}

.mobile .myTickets__ticketCard-content-details-descHeader {
  font-size: clamp(0.9rem, 1vh, 2rem);
  color: #b7b7b7;
}

.mobile .myTickets__ticketCard-content-details-replyPlaceholder {
  color: #b7b7b7;
  font-size:  clamp(0.9rem, 1vh, 2rem);
}