.tabletPortrait .vendorOnboarding__left{
    display: none;
}

.tabletPortrait .vendorInitiate-form{
    flex-direction: row;
}

.tabletPortrait .vendorOnboarding__right-content{
    width: 100%;
    padding: 20px 0px;
}

.tabletLandscape .vendorOnboarding__right-content{
    width: 100%;
    padding: 20px 0px;
}

.tabletLandscape .vendorOnboarding__left {
    width: 210px;
    height: 184px;
}

.tabletLandscape .vendorInitiate-form--right {
    width: 259px;
    height: 220px;
    align-self: center;
}

.tabletPortrait .vendorInitiate-form--left{
    width: 44%;
    align-items: center;
}

.tabletPortrait .vendorInitiate-form{
    align-items: center;
}

.tabletPortrait .vendorInitiate-form--right{
    width: 293px;
    height: 249px;
    margin-top: 20px;
}