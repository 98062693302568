.mobile .div__productDetail-topContainer{
    flex-direction: column;
    align-items: center;
    width: fit-content;
}

.mobile .div__productDetail-images{
    width: 100%;
    align-items: center;
}

.mobile .div__productDetail-imagesSection{
   width: 100%;
   max-width: 460px;
   margin-left: -10%;
}

.mobile .div__productDetail-imagesSection {
    display: flex;
    justify-content: space-between;
}

.mobile .div__productDetail-content{
    width: 100%;
    margin-bottom: -50px;
}

.mobile .div__productDetail-inventoryCart{
    width: 100%;
    opacity: 1;
    padding: 0px;
    border-radius: 0px;
}    

.mobile .div__productDetail-content{
    margin-left: 0px;
}

.mobile .productPunDescription, .deliveryInstruction {
    width: 80%;
}

.mobile .div__productDetail-content .productTitle,
.mobile .div__productDetail-content .productDetail,
.mobile .div__productDetail-content .productPunDescription,
.mobile .div__productDetail-content .div__productDetail-content-rating,
.mobile .div__productDetail-content .div__productDetail-content-cartQuantity,
.mobile .div__productDetail-content .div__productDetail-content-sizeSelect{
    margin: 8px 15px;
}

.mobile .btnWishlist,.mobile .btnAddToCart,.mobile .btnBuyNow{
    width: 80%;
    height: 60px;
}

.mobile .div__productDetail-cartActions{
    flex-direction: column;
    align-items: center;
}

.mobile .div__productDetail-images{
    padding: 0px;
}

.mobile .div__productDetail-imagesList{
    padding: 0;
    padding-left: 38px;
}

.mobile .div__productDetail-memePreview{
    margin: 120px 0px;
}

.mobile .div__productDetail-priceColorGender{
    flex-wrap: wrap;
}

.mobile .div_productDetail-selectColor{
    order: 99;
    margin: 10px 0px;
}