.tabletLandscape .div__OTPAuth-formContainer {
  width: 30%;
}
.tabletLandscape #btnInput.btnActionOTP {
  width: 290px;
}

.tabletLandscape .appContainer {
  margin-top: 33px;
}


.tabletPortrait .div__OTPAuth-formContainer{
  width: 100%;
  margin: 0px;
}

.tabletPortrait .div__OTPAuth-form{
  margin-left: 20px;
}

.tabletPortrait .div__OTPAuth-formContainer-verifyPhone{
  margin-left: 20px;
}