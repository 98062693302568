.div__userCart-orderCheckout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  border-radius: 12px;
  padding: 10px 20px;
  flex: 0.3 1;
  border: 0.5px solid white;
  margin: 18px 0px;
  width: 25%;
}
