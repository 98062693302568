.profile-page__banner-container {
  width: 100%;
  height: 372px;
  background-image: url("../../../assets/wil-stewart-p-ha-nr-cpb-ym-unsplash.png");
  background-color: #fff;
  position: relative;
}

.profile-page__banner-overlay {
  height: inherit;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
}

.profile-page__banner-name {
  font-size: clamp(1.5rem,3vw,3rem);
  color: #fff;
  margin-top: 5.3%;
  text-align: center;
}

.profile-page__banner-name .MuiButton-root {
  width: 290px;
  height: 62px;
  font-size: 20px;
  border-radius: 5px;
  padding: 0;
}

.profile-page__banner-footer__left,
.profile-page__banner-footer__right {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  height: 120px;
}

.profile-page__banner-title {
  width: 220px;
  height: 76px;
  font-family: Helvetica;
  font-size: clamp(1rem, 1.5vw,2rem);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #c4c4c4;
}

.profile-page__banner-footer {
  height: inherit;
  position: absolute;
  height: inherit;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.profile-page__banner-count,
.profile-page__banner-prompt {
  font-family: Helvetica;
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.profile-page__banner-prompt {
  font-size: clamp(1.5rem, 2vw, 2rem);
}

.profile-page__banner-footer__content-small,
.profile-page__banner-footer__content-big {
  text-align: center;
  margin: 0px 10%;
}

.profile-page__banner-overlay .MuiAvatar-circular.MuiAvatar-root {
  bottom: -33%;
  position: absolute;
  transform: translateY(-50%);
  aspect-ratio:1/1;
  width: clamp(4rem,15vw,10rem);
  height: clamp(4rem,15vw,10rem);
  font-size: clamp(0.8rem,0.8vw,1rem);
}

.profile-page__banner-edit-icon {
  color: #c4c4c4;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 30px!important;
}
