.mobile .myOrder__card-content-timeline .myOrder__card-content-timeline-icon {
    height: 15px;
    width: 15px;
}

.mobile .myOrder__card-content-container {
  display: flex;
  width: 65%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}