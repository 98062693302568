.tabletPortrait .div__productDetail-ratings{
    margin: 50px 0px;
}

.tabletPortrait .div__productDetail-ratings-list, .div__productDetail-ratings-feedback{
    padding: 15px;
}

.tabletLandscape .div__ratings-percentageContainer{
    width: 164px;
}

.tabletPortrait .div__ratings-percentageContainer{
    width: 83px;
}

.tabletLandscape .div__feedbackDetail-userRating {
    flex-basis: 11%;
}

.tabletPortrait .div__feedbackDetail-userRating {
    flex-basis: 20%;
}