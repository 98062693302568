.rowDetail__container {
    display: flex;
}

.rowDetail__container p {
    padding: 15px;
    font-size: clamp(0.8rem,1vw,1rem);
    word-break: break-word;
}

.rowDetail__label {
    color: #585858;
    flex: 1;
}

.rowDetail__description {
    color: #707070;
    flex: 1;
    width: 100px;
}