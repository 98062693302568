.tabletLandscape .lnkLogo {
  width: 100%;
  height: 140px;
  align-self: center;
}
.tabletLandscape .imgLogo {
  width: 50%;
  height: 140px;
  margin: auto;
}
.tabletLandscape .div__vendorLogin-container {
  background: #585858;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.tabletLandscape .lblSignIn {
  font-size: 22px;
  font-stretch: normal;
  letter-spacing: normal;
  align-self: flex-start;
  color: #fff;
  margin-bottom: 15px;
}

.tabletLandscape #btnInput.btnSignIn,
.tabletLandscape #btnInput.btnRegister {
  width: 235px;
  height: 45px;
  font-size: 18px;
}

.tabletLandscape .div__vendorLogin-form-googleLogin,
.tabletLandscape .div__vendorLogin-form-facebookLogin {
  width: 238px;
  height: 40px;
  background: #4285f4;
  color: white;
  display: flex;
  font-size: 18px;
  cursor: pointer;
}

.tabletPortrait .imgLogo {
  width: 139px;
  height: 122px;
  align-self: center;
}
.tabletPortrait .div__vendorLogin-formContainer {
  width: 100%;
  height: 100%;
  margin: 0px;
  border-radius: 0px;
}
.tabletPortrait .div__vendorLogin-form {
  margin-left: 40px;
}
.tabletPortrait {
  width: 100%;
  height: 170px;
  align-self: center;
}

.tabletPortrait .lnkLogo {
  width: 100%;
  height: 140px;
  align-self: center;
}

.tabletPortrait #btnInput.btnSignIn,
.tabletPortrait #btnInput.btnRegister {
  width: 430px;
  height: 45px;
  font-size: 18px;
}

.tabletPortrait .div__vendorLogin-form-googleLogin,
.tabletPortrait .div__vendorLogin-form-facebookLogin {
  width: 470px;
  height: 40px;
  background: #4285f4;
  color: white;
  display: flex;
  font-size: 18px;
  cursor: pointer;
}

.tabletLandscape #routesContainer,
.tabletPortrait #routesContainer {
  height: 100%;
  padding: 0;
}

.tabletLandscape .div__vendorLogin-container,
.tabletPortrait .div__vendorLogin-container {
  height: 100%;
}

.tabletLandscape .div__vendorLogin-formContainer,
.tabletPortrait .div__vendorLogin-formContainer {
  border: none;
}