.tabletLandscape .div__userWishlist-container {
    margin-top: 10px;
}

.tabletPortrait .div__userWishlist-container {
    margin-top: 10px;
}

.tabletPortrait .div__userWishlist-results,
.tabletLandscape .div__userWishlist-results {
    justify-content: space-between;
} 

.tabletPortrait .div__userWishlist-container .div__productCard {
    width: 220px;
}