.div__userCart {
  margin: 0px auto;
}

.div__userCart-container {
  display: flex;
  flex-direction: column;
}

.div__userCart-container-header {
  display: flex;
  margin-left: 6%;
  margin-top: 47px;
  font-size: clamp(1.5rem, 2vw, 2.5rem);
}
#iconCart {
  width: 30px;
  height: 30px;
  margin-right: 7px;
  margin-top: 3px;
}

.lblRecommendedProducts {
  margin: 0px 18px;
  margin-top: 15px;
  font-size: clamp(1.2rem, 2vw, 2rem);
}

.div__userCart-overview {
  display: flex;
  margin: 10px 5%;
  justify-content: space-around;
}

.div__userCart-overview .div__userCart-grid {
  flex: 0.65;
  height: 700px;
  overflow: auto;
  padding-bottom: 10px;
  margin-top: 0px;
}

.div__userCart-grid::-webkit-scrollbar {
  display: none;
}

.div__userCart-grid {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.div__userCart-emptyCart {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
}

.imgEmptyCart {
  width: 320px;
  height: 266px;
}

.lblEmptyCartMessage,
.lblEmptyCartMessage2 {
  margin: 20px 0px;
}

.lblEmptyCartMessage {
  font-size: 30px;
}

.lblEmptyCartMessage2 {
  font-size: 22px;
}

.div__userCart-container .btnContinueShopping,
.div__userCart-container .btnContinueShopping:hover {
  background: #86c232;
  color: white;
  align-self: center;
  text-transform: none;
  font-weight: bold;
  font-size: 18px;
}

.div__snackbar-cart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}

.div__snackbar-cart .iconCart {
  margin: 0px 10px;
}
@media (max-width: 1279px) {
  .div-uploadedImage {
    height: 100px;
    width: 100px;
    border-radius: 13px;
  }
}
