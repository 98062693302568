.mobile .div__productList-filters #btnInput {
    width: 34%;
    font-size: clamp(0.8rem, 1.5vh, 1.2rem);
}
.mobile .product__inventoryList{
    width: 100%;
    padding: 11px 0px;
}

@media (max-width: 360px) {
    .div__productList-filters{
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile .div__productList-filters #btnInput {
        width: 100%;
        font-size: clamp(0.8rem, 1.5vh, 1.2rem);
    }

    .mobile .div__productList-filters .div__txtInput{
        width: 100%;
    }
}