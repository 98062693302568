.mobile .div__addProductForm-container{
    flex-direction: column;
    width: 102%;
}

.mobile .div__addProductForm-primary,
.mobile .div__addProductForm-secondary {
    width: 96%;
    margin : 0px 10px ;
    padding: 0px;
}

.mobile .div__addProductForm-inventoryList {
    width: 97%;
    margin: 20px 10px;
}

.mobile .div__addProductForm-actions {
    width: 100%;
    margin: 20px 10px;
}

.mobile .headerAddProduct {
    margin-left: 36%;
}
.div__addProductForm .iconCancelProduct {
    margin-right: 10px;
}

.mobile .div__addProductForm-secondary-dropdown .div__dropdown-input {
    padding: 0px;
}