.PaymentUPI__container{
    width: 100%;
}

.PaymentUPI__header,.PaymentUPI__subheader{
    width: 100%;
    color: black;
}

.PaymentUPI__Card{
    padding: 20px;
    border: solid 3px #222629;
    border-radius: 30px;
}


.PaymentUPI__header{
    margin: 30px 0px;
}

.img__UPIlogo{
    aspect-ratio: 141/38;
    width: 30%;
    margin: 15px;
}

.PaymentUPI__subheader{
    margin: 0px 15px;
    font-size: clamp(0.8rem,1.1vw,1.2rem);
    color: grey;
}

.label__inputID{
    font-size: clamp(0.8rem,1.1vw,1.2rem);
    margin: 15px;
}

.PaymentUPI__inputID{
    width: 100%;
}

.PaymentUPI__inputID .MuiTextField-root{
    width: 60%;
    margin: 0px 15px;
}

.PaymentUPI__SaveUpiID{
    margin: 5px 15px;
}