.mobile .myTickets__container {
  width: 95%;
  align-items: center;
  padding: 0px;
}

.mobile .myTickets__filters .div__dropdown-input {
  width: 70%;
}

.mobile #btnInput.ticket_detail-addTicket {
  width: 175px;
  height: 39px;
  font-size: clamp(1rem, 1.5vw, 1.8rem);
  border-radius: 5px;
  margin-top: 0px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}