.tabletLandscape .admin__ticketsList__filters{
    flex-direction: column;
}

.tabletLandscape .div__searchInput{
    width: 100%;
}

.tabletLandscape .div__Filters{
    width: 100%;
    margin: 15px 0px;
}

.tabletPortrait .admin__ticketsList__filters{
    flex-direction: column;
}

.tabletPortrait .div__searchInput{
    width: 100%;
}

.tabletPortrait .div__Filters{
    width: 100%;
    margin: 15px 0px;
}