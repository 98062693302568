.manageAddress {
    width: 100%;
}

.manageAddress p {
    color: black;
}

.manageAddress__header {
    display: flex;
    margin-left:10px
}

.manageAddress__header--logo {
    width: 90px;
    height: 90px;
}

.manageAddress__header--right {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.manageAddress__header--right p {
    color: black
}

.manageAddress__header--right-memeT {
    font-size: clamp(1rem,1.7vw,2.2rem);
}

.manageAddress__header--right-address {
    font-size: clamp(1.2rem,2vw,2.2rem);
}

.manageAddress__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.manageAddress_list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
}

#btnInput.manageAddress__list-item-confirmAddress {
    width: 50%;
    height: 60px;
    font-size: clamp(1rem,1.5vh,1.5rem);
}

.manageAddress__list {
    padding: 0px 10px;
    width: 100%;
    height: 540px;
    overflow-y: auto;
}

.manageAddress__list::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.manageAddress__list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.manageAddress__list-item--radio {
    align-self: flex-start;
}

.manageAddress__form{
    width: 45%;
}

.manageAddress__form .addressForm-container span.errorMessage{
    width: 100%;
    color: crimson !important;
}

@media (max-width: 860px) {
    .manageAddress_list-container, .manageAddress__form{
        width: 100%;

    }
    .manageAddress__list{
        height: auto;
    }
}

@media (max-width: 424px) {

    .manageAddress__list{
        width: 87%;
    }
    .manageAddress__list-item-content-actions button#btnInput{
        width: 70%;
        font-size: 14px;
    }
    
}



@media (max-width: 346px) {

    .manageAddress__list-item-content{
        flex-direction: column;
    }
}