.tabletPortrait .imgBanner {
  aspect-ratio: 1920/577;
  width: 100%;
}
.tabletLandscape .imgBanner {
  aspect-ratio: 1920/577;
  width: 100%;
}
.tabletPortrait .div__landingPage-topLanguages,
.tabletPortrait .div__landingPage-latestAdditions {
  letter-spacing: 5px;
}
.tabletLandscape .div__landingPage-topLanguages,
.tabletLandscape .div__landingPage-latestAdditions {
  letter-spacing: 9px;
}

.tabletPortrait .div__landingPage-latestAdditions-list {
  width: 100%;
  justify-content: center;
}

.tabletLandscape .div__landingPage-latestAdditions-list {
  padding: 2px 10px;
  width: 85%;
  margin: 10px auto;
}

.tabletLandscape .div__landingPage-topLanguages-list {
  padding: 5px;
}
.tabletPortrait .div__landingPage-topLanguages-list {
  padding: 5px;
}

.tabletPortrait .div__footer-languagesList {
  flex: 0.5;
}
