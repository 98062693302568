.mobile .div__userCart-overview {
  flex-direction: column;
}
.mobile .div__userCart-orderCheckout {
  width: 88%;
  align-content: center;
  margin-top: 25px;
}
.mobile .imgEmptyCart {
  width: 150px;
  height: 150px;
}
