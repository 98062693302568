.mobile .div__OTPAuth-formContainer{
    width: 100%;
    margin: 0px;
  }
  
  .mobile .div__OTPAuth-form{
    margin-left: 34px;
  }
  
  .mobile .div__OTPAuth-formContainer-verifyPhone{
    margin-left: 0px;
  }