.bankAccount__card {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 92%;
    min-height: 200px;
    margin: 10px;
    border-radius: 8px;
}

.bankAccount__card-details {
    height: 80%;
}

.bankAccount__card-details--left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
    height: 100%;
    margin: 0px 45px;
    margin-top: 10px;
}

.bankAccount__card-details--left-name,
.bankAccount__card-details--left-accountNumber,
.bankAccount__card-details--left-holderName,
.bankAccount__card-details--left-branch {
    color: #8a8a8a;
    font-size: clamp(1rem, 1.2vw, 2rem);
    margin: 8px 0px;
}

.bankAccount__card-details--left-name {
    font-size: clamp(1.3rem, 1.5vw, 2rem);
    color: #212628;
}

.bankAccount__card-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.bankAccount__card-actions-update,
.bankAccount__card-actions-remove,
.bankAccount__card-actions-setPrimary {
    color: #96db3a;
    text-transform: uppercase;
    font-size: clamp(0.9rem, 1.2vw, 1.2rem);
    cursor: pointer;
    padding: 8px;
}