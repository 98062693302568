
.orderDetail__content--left-items {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rowDetail__container.orderDetail__content--left-item-content-value p {
    font-size: clamp(0.9rem, 1.2vw, 2rem);
}

.div__detail-item-content.orderDetail__content--left-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    overflow-y: auto;
}

.orderDetail__content--right-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.orderDetail__content--left-item::-webkit-scrollbar {
    display: none;
}
  
.orderDetail__content--left-item {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.orderDetail__content--left-item-header {
    padding: 10px;
}

.orderDetail__content--left-item-selected {
    background-color: #cbcbcb;
}

.orderDetail__content--left-item-selected p {
    color: #585858;
}

.orderDetail__content--left-item-details {
    display: flex;
    border: 1px solid #707070;
    margin: 10px;
    border-radius: 11px;
    align-items: center;
    justify-content: space-around;
}

.orderDetail__content--left-items .orderDetail__content--left-item-header {
    padding: 15px;
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    font-weight: normal;
    text-align: center;
}

.orderDetail__content--left-item-image {
    width: 30%;
    aspect-ratio: 320/437;
    border-radius: 8px;
}

.orderDetail__container-content--right{
    width: 66%;
}