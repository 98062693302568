.div__searchPage-filters {
    margin: 10px;
    padding: 0.5em;
    width: 80%;
}

.div__searchPage-filters .MuiFormControlLabel-label {
    color: white;
}

.lblFilters {
    font-size: 30px;
    margin: 0px 15px;
}

.div__searchPage-filters_gender,
.div__searchPage-filters_language,
.div__searchPage-filters_sizeSymbol,
.div__searchPage-filters_price,
.div__searchPage-filters_discount {
    margin: 0.5em;
}

.div__searchPage-filters_gender-options,
.div__searchPage-filters_language-options,
.div__searchPage-filters_sizeSymbol-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.div__searchPage-filters_gender-options {
    width: 81%;
}

.filterLabel, .filterValue {
    margin: 8px 12px;
    color: white;
}

.filterValue {
    color: lightgray;
    margin-top: 10px;
}


.div__searchPage-results {
    flex: 0.8;
}

.div__checklist-container {
    display: flex;
}

.div__searchPage-filters-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.div__searchPage-filters-label p {
    color: #86c232;
    cursor: pointer;
}

.div__inputSlider {
    align-self: flex-start;
    justify-content: flex-start;
}