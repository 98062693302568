.div__txtAutocomplete_tags {
    width: 100%;
    flex: 1;
}

.div__txtAutocomplete_tags .MuiFormControl-root.MuiFormControl-marginNormal {
    margin: 0px;
    height: 36px;
}

.div__txtAutocomplete_tags .lblInput {
    font-size: clamp(1rem,1.2vw,1.5rem);
}