.orderDetail__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.orderDetail__container-header {
    color: #585858;
    font-size: clamp(1.2rem, 2.5vw, 2rem);
}

.orderDetail__container-content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.orderDetail__container-content--left-items {
    display: flex;
    flex-direction: column;
    width: 33%;
}

.rowDetail__container.orderDetail__container-content--left-item-content-value p {
    font-size: 18px;
}

.div__detail-item-content.orderDetail__container-content--left-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 690px;
    overflow-y: auto;
}

.orderDetail__container-content--right-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.orderDetail__container-content--left{
    width: 33%;
}

.orderDetail__container-content--left-item::-webkit-scrollbar {
    display: none;
}
  
.orderDetail__container-content--left-item {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.orderDetail__container-content--right-top .div__detail-item {
    margin: 5px;
} 

.orderDetail__container-content--left-item-details {
    display: flex;
    border: 1px solid #707070;
    margin: 10px;
    border-radius: 11px;
    align-items: center;
}

.orderDetail__container .orderDetail__container-content--left-item-header,
.orderDetail__container .orderDetail__container-content--right-item-header {
    padding: 10px;
    font-size: clamp(1.2rem,1.2vw,2rem);
    font-weight: normal;
    text-align: center;
}

.orderDetail__container-content--left-item-image {
    width: 100%;
    height: 170px;
    border-radius: 8px;
}

.orderDetail__container-content--right, 
.orderDetail__container-content--right-top,
.orderDetail__container-content--right-bottom {
    display: flex;
}


.orderDetail__container-content--right-item .rowDetail__label {
    flex: 0.5;
}

.orderDetail__container-content--right {
    flex-direction: column;
}

.orderDetail__container .orderDetail__container-content--right-orderStatus {
    width: 50%;
    height: auto;
    margin: 10px;
}

.orderDetail__container-content--right-item-orderId {
    text-transform: uppercase;
}

.orderDetail__container-content--right-deliveryStatus {
    width:50%;
    height: auto;
}

.orderDetail__container-content--right-recipientDetails {
    width: 715px;
    height: auto;
}

.orderDetail__container-content--right-paymentDetails {
    width: 65%;
    height: auto;
}

.orderDetail__container-content--right-item-header-icon {
    font-size: 30px;
    margin-right: 10px;
    margin-top: 2px;
}

.orderDetail__container-content--right-vendorActions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.orderDetail__container-content--right-vendorActions .orderDetail__container-content--right-vendorActions-invoice,
.orderDetail__container-content--right-vendorActions .orderDetail__container-content--right-vendorActions-invoice:hover {
    width: 343px;
    height: 66px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.37);
    border: solid 2px #74a730;
    color: #74a730;
    font-size: 24px;
    text-transform: uppercase;
}

.orderDetail__container-content--right-vendorActions .orderDetail__container-content--right-vendorActions-dispatch,
.orderDetail__container-content--right-vendorActions .orderDetail__container-content--right-vendorActions-dispatch:hover {
    width: 343px;
    height: 66px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.37);
    background-color: #74a730;
    color: white;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 25px;
}

@media (max-width : 1038px){
    .orderDetail__container-content--left {
        width : 100%;
    }
    .orderDetail__container-content--right{
        width : 100%;
    }
}

@media (max-width : 600px){
    .orderDetail__container-content--right, 
    .orderDetail__container-content--right-top,
    .orderDetail__container-content--right-bottom {
        display: flex;
        flex-direction: column;
    }

    .orderDetail__container-content--right-orderStatus {
        width: 100%;
        height: auto;
    }
    .orderDetail__container-content--right-deliveryStatus{
        width: 100%;
        height: auto;
    }
    .orderDetail__container-content--right-paymentDetails{
        width : 100%;
        height: auto;
    }
    .orderDetail__container-content--right-recipientDetails{
        width: 100%;
    }
    .orderDetail__container-content--right-orderStatus, .orderDetail__container-content--right-deliveryStatus,
    .orderDetail__container-content--right-recipientDetails, .orderDetail__container-content--right-paymentDetails{
        margin : 10px 0px;
    }

    .orderDetail__container .orderDetail__container-content--right-orderStatus {
        width: 100%;
    }

    .MuiDrawer-root.drawerMenu.myOrdersList__orderDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
    }

}

@media (max-width : 900px) {
    .orderDetail__container .orderDetail__container-content--right-orderStatus {
        width: 100%;
    }

    .drawerMenu.myOrdersList__orderDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
    }
}