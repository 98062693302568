.tabletLandscape .vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-header{
    flex-direction: column;
}

.tabletPortrait .vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-header{
    flex-direction: column;
    width: 174%;
}

.tabletLandscape .vendorDashboard__gridContainer--vendorTransactions-header-name{
    width: 100%;
}

.tabletPortrait .vendorDashboard__gridContainer--vendorTransactions-header-name{
    width: 100%;
}

.tabletLandscape .div__Filters{
    width: 100%;
    margin: 10px 0px;
    justify-content: space-between;
}

.tabletPortrait .div__Filters {
    width: 100%;
    margin: 10px 0px;
    justify-content: space-around;
}

.tabletPortrait .vendorDashboard__gridContainer--vendorTransactions .vendorDashboard__gridContainer--vendorTransactions-content {
    width: 179%;
}