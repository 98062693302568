.vendorWalletDashboard__monthlySummary {
    display: flex;
    flex-direction: column;
    background: #212628;
    height: 290px;
    width: 100%;
}

.vendorWalletDashboard__monthlySummary-header,
.vendorWalletDashboard__monthlySummary-header-calendar {
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    color: #cbcbcb;
    margin: 10px;
    vertical-align: middle;
}

.vendorWalletDashboard__monthlySummary-header-calendar {
    margin: 0px 10px;
}

.vendorWalletDashboard__monthlySummary-content .MuiGrid-item {
    display: flex;
    height: 100%;
}

.vendorWalletDashboard__monthlySummary-content .vendorWalletDashboard__monthlySummary-gridItem {
    display: flex;
    flex-direction: column;
    height: 145px;
    width: 85%;
    align-items: center;
    justify-content: space-evenly;
}

.vendorWalletDashboard__monthlySummary-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    margin-left: 30px;
    height: 100%;
}

.vendorWalletDashboard__monthlySummary-gridItem-header {
    font-size: clamp(1rem, 1.2vw, 2rem);
}

.vendorWalletDashboard__monthlySummary-gridItem-header,
.vendorWalletDashboard__monthlySummary-gridItem-footer {
    align-self: baseline;
    color: #b9b9b9;
    font-size: clamp(0.7rem, 2vw, 1rem);
}

.vendorWalletDashboard__monthlySummary-gridItem-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.vendorWalletDashboard__monthlySummary-gridItem-content--left {
    font-size: clamp(1rem,2.5vw,2rem);
}

.vendorWalletDashboard__monthlySummary-gridItem-content--right,
.vendorWalletDashboard__monthlySummary-gridItem-content--right .analytics__card--right-increment,
.vendorWalletDashboard__monthlySummary-gridItem-content--right .analytics__card--right-decrement {
    font-size: clamp(0.75rem,2vw,1.6rem);
    vertical-align: middle;
}

.vendorWalletDashboard__monthlySummary-gridItem-content--right .analytics__card--right-increment {
    color: #74a730;
}

.vendorWalletDashboard__monthlySummary-gridItem-content--right .analytics__card--right-decrement {
    color: #d40000;
}

.vendorWalletDashboard__monthlySummary-gridItem-content-totalSales,
.vendorWalletDashboard__monthlySummary-gridItem-content-totalRevenue,
.vendorWalletDashboard__monthlySummary-gridItem-content-totalWithdraw {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.vendorWalletDashboard__monthlySummary-gridItem-content-totalWithdraw {
    justify-content: flex-start;
}

.verticalDivider{
    margin: 0px 20px;
}

.vendorDashboard__gridContainer--vendorTransactions .div__tableList-container th.headerColumn,
.vendorDashboard__gridContainer--vendorTransactions .div__tableList-container tr.dataRow td,
.vendorDashboard__gridContainer--vendorTransactions .div__tableList-container .noDataRow{
    font-size: clamp(1rem, 1.2vw, 2rem);
}

.vendorDashboard__gridContainer--vendorTransactions .MuiTablePagination-selectLabel,
.vendorDashboard__gridContainer--vendorTransactions .MuiTablePagination-selectIcon,
.vendorDashboard__gridContainer--vendorTransactions .MuiTablePagination-select.MuiSelect-select,
.vendorDashboard__gridContainer--vendorTransactions .MuiTablePagination-displayedRows,
.vendorDashboard__gridContainer--vendorTransactions .MuiTablePagination-actions .MuiSvgIcon-root {
    font-size: clamp(1rem, 1.2vw, 2rem) !important;
}