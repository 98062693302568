.tabletPortrait .div__productDetail-content-cartOptions {
    align-items: center;
    flex-direction: row;
    margin-top: 5px;
}

.tabletPortrait .div__productDetail-content-cartQuantity {
    margin-top: 20px;
}

.tabletPortrait .div__productDetail-content-sizeSelect {
    margin-top: -15px;
}

.tabletPortrait .div__productDetail-imagePreview .imgDetailPreview {
    width: 145%;
}

.tabletLandscape .div__productDetail-inventoryCart {
    width: 140%;
}