.div__txtInput {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.div__txtInput input {
    border-radius: 10px;
    color: black;
    background-color: white;
    padding: 0.5em;
}

.div__txtInput_label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div__txtInput_label-icon {
    display: flex;
}

.div__txtInput .lblInput {
    font-size: clamp(1rem,1.2vw,1.5rem);
}

.MuiOutlinedInput-root.MuiInputBase-root {
    background-color: white;
    border-radius: 8px;
}

.lnkForgot {
    color: #86c232;
    text-decoration: underline;
    margin: auto 0px;
}