.div__vendorLogin-container {
  background: #585858;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.div__vendorLogin-formContainer {
  display: flex;
  width: 25%;
  margin: 5% 30px;
  padding: 1em 1.5em;
  border-radius: 21px;
  border: solid 2px #585858;
  background-color: #222629;
  flex-direction: column;
  font-family: Helvetica;
  align-self: flex-end;
  height: 700px;
}

.div__vendorLogin-form {
  display: flex;
  flex-direction: column;
}

.lnkLogo {
  width: 100%;
  height: 170px;
  align-self: center;
}

.imgLogo {
  width: 50%;
  height: 170px;
  margin: auto;
}

.div__txtInput input {
  border-radius: 10px;
}

.div__txtInput_label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.div__vendorLogin-form .lblInput {
  font-size: 16px;
  color: white;
  margin-bottom: 5px;
}

.lblSignIn {
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  font-stretch: normal;
  letter-spacing: normal;
  align-self: flex-start;
  color: #fff;
  margin-bottom: 15px;
}

.div__vendorLogin-form .dividerLine {
  height: 0px;
  border: 0.5px solid white;
  width: 90%;
}

.div__vendorLogin-accountRegister {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 25px 0px;
}

.div__vendorLogin-accountRegister .div__vendorLogin-accountRegister-label {
  color: white;
  text-align: center;
  margin: 0px 10px;
  width: 100%;
}

#btnInput.btnSignIn,
#btnInput.btnRegister {
  width: 300px;
  height: 45px;
  font-size: 22px;
}

.div__vendorLogin-socialMedia {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 25px;
}

.div__vendorLogin-form .kep-login-facebook {
  align-self: center;
  padding: 10px;
  margin: 10px;
  text-transform: none;
  border-radius: 8px;
}

.div__vendorLogin-form-facebookLogin {
  margin-top: 35px;
  background: #1877f2;
}

.div__vendorLogin-form-googleLogin,
.div__vendorLogin-form-facebookLogin {
  width: 340px;
  height: 40px;
  background: #4285f4;
  color: white;
  display: flex;
  font-size: 18px;
  cursor: pointer;
}

.div__vendorLogin-form-googleLogin p,
.div__vendorLogin-form-facebookLogin p {
  display: inline-flex;
  margin: auto;
}

.imgFacebookLogo {
  height: 35px;
  width: 35px;
  margin: auto 5px;
}
