.vendorInitiate-form {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.vendorInitiate-form--left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.vendorInitiate-form--left-vendorName .MuiOutlinedInput-root,
.vendorInitiate-form--left-vendorEmail .MuiOutlinedInput-root {
    width: 100%;
    height: 55px;
}

#btnInput.vendorInitiate-form--left-sendEmailOTP {
    width: 100%;
    height: 57px;
    align-self: center;
    font-size: clamp(1rem, 1vw, 2rem);
    text-transform: uppercase;
}

.vendorInitiate-form--right {
    width: 324px;
    height: 275px;
    align-self: center;
}

.vendorInitiate-form--left .div__txtInput .lblInput{
    font-size: clamp(1rem, 1vw, 1.8rem);
}