.tabletPortrait .imgNoSearchResults,
.tabletLandscape .imgNoSearchResults {
    width: 75%;
}

.tabletPortrait #btnInput.btnSuggestMemes,
.tabletLandscape #btnInput.btnSuggestMemes {
    width: 50%;
    height: 50px;
    font-size: clamp(1rem, 1.5vh, 1.5rem);
}

.tabletPortrait .div__noSearchResults {
    width: 90%;
}

.tabletLandscape .div__noSearchResults {
    width: 80%;
}