.vendorOrderCard {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 125px;
}

.vendorOrderCard__left {
    display: flex;
    margin: 0px 15px;
    width: 100%;
}

.vendorOrderCard__left .vendorOrderCard__left-image {
    width: 60px;
    height: 60px;
}

.vendorOrderCard__left-detail {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: white;
    margin: 0px 10px;
    width: 100%;
}

.vendorOrderCard__left-detail .rowDetail__label,
.vendorOrderCard__left-detail .rowDetail__description {
    font-size: clamp(1rem, 1vw, 1.5rem);
    color: white;
    padding: 8px 16px;
}

#btnInput.vendorOrderCard__detail-viewOrderDetail {
    width: 143px;
    height: 44px;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #74a730;
    text-transform: uppercase;
}