.mobile .vendorEmailOTP__formContainer{
    flex-direction: column-reverse;
}

.mobile .vendorEmailOTP__formContainer--right{
    width: 30%;
    margin-bottom: 20px;
}

.mobile .vendorOnboarding__right-content{
    padding: 30px 0px;
}