.vendorTransactionCard {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    min-height: 125px;
    /* flex-wrap: wrap; */
}

.vendorTransactionCard__left {
    display: flex;
    width: 100%;
}

.vendorTransactionCard__left .vendorTransactionCard__left-icon {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    align-self: center;
    /* font-size: clamp(1rem, 1.2vw, 1.5rem); */
}

.vendorTransactionCard__left-icon--credit {
    color: #b4ff4f;
}

.vendorTransactionCard__left-icon--debit {
    color: #d40000;
}

.vendorTransactionCard__left-detail {
    display: flex;
    flex-direction: column;
    font-size: clamp(0.8rem, 1vw, 1.5rem);
    color: white;
    width: 100%;
}

.vendorTransactionCard__left-detail .rowDetail__label,
.vendorTransactionCard__left-detail .rowDetail__description {
    font-size:clamp(0.8rem, 1vw, 1.5rem);
    color: white;
    padding: 8px 16px;
}

#btnInput.vendorTransactionCard__detail-viewTransactionDetail {
    width: 143px;
    height: 44px;
    border-radius: 30px;
    border: solid 1px #707070;
    background-color: #74a730;
    text-transform: uppercase;
}

.vendorTransactionCard__right-transactionAmount {
    display: inline-flex;
    font-size: clamp(1rem,2vw,2rem);
    color: #cbcbcb;
}