.mobile .vendorOnboarding__left{
    display: none;
}

.mobile .vendorInitiate-form{
    flex-direction: column-reverse;
}

.mobile .vendorInitiate-form--left{
    width: 85%;
    align-items: center;
}

.mobile .vendorInitiate-form{
    align-items: center;
}

.mobile .vendorInitiate-form--right{
    width: 206px;
    height: 175px;
    margin-top: 20px;
}


.mobile .vendorOnboarding .vendorOnboarding-icon{
    height: 25px;
    width: 25px;
}