.manageAddress__list-item {
    width: 100%;
    height: auto;
    margin: 15px 0px !important;
}

.manageAddress__list-item-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.manageAddress__list-item-content-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 45%;
    flex: 1;
}

.manageAddress__list-item-content .manageAddress__list-item--radio {
    padding: 5px 0px;
}

.manageAddress__list-item-recipientName {
    font-size: clamp(1rem,2vw,1.5rem);
    padding: 5px 10px;
}

.manageAddress__list-item-recipientAddress,
.manageAddress__list-item-recipientContact {
    font-size: clamp(1rem,1.3vw,1.5rem);
    padding: 10px;
}

.manageAddress__list-item-content-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right:10px;
}

.manageAddress__list-item-content-actions button#btnInput {
    width: 100%;
    height: 60px;
    background-color: white;
    color: #74a730;
    border: 1px solid #74a730;
    margin: 10px;
}