/* .bankAccount__form-header--title,
.bankAccount__form-header--subTitle {
    color: #585858;
} */

.bankAccount__form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.bankAccount__form-header--title {
    font-size: 37px;
    margin-bottom: 11px;
}

.bankAccount__form-header--subTitle {
    font-size: 22px;
    margin-bottom: 22px;
}