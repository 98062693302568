.vendorDashboard {
    width: 100%;
}

.vendorDashboard__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
    margin: 28px 0px;
}


.vendorDashboard__header--left,
.vendorDashboard__header--left-icon {
    color: #f5f5f5;
    font-size: clamp(1.5rem, 2.5vw, 2.1rem);
    vertical-align: middle;
    align-items: baseline;
}

.vendorDashboard__header .vendorDashboard__header--left-icon {
    color: #cbcbcb;
    font-size: clamp(1.5rem, 2.5vw, 2.1rem);
    vertical-align: middle;
}

.vendorDashboard__header .vendorDashboard__header--right-addProduct-button,
.vendorDashboard__header .vendorDashboard__header--right-addProduct-button:hover {
    width: 236px;
    height: 47px;
    border-radius: 10px;
    background-color: #74a730;
    font-size: clamp(1rem, 1.3vw, 1.5rem);
    color: white;
}

.vendorDashboard__header .vendorDashboard__header--right .vendorDashboard__header--right-addProduct-buttonIcon {
    font-size: clamp(0.8rem, 1.3vw, 2rem);
}

.vendorDashboard__header--right-addProduct-link {
    text-decoration: none;
}

.vendorDashboard__header--right-addProduct-text {
    color: #f5f5f5;
    font-size: 18px;
    margin: 8px 0px;
    text-align: center;
}

.vendorDashboard__gridContainer {
    margin: 30px 0px;
    padding: 0px 2%;
}

.vendorDashboard__gridContainer-dispatchOrders {
    height: 425px;
}

.vendorDashboard__gridContainer .vendorDashboard__gridContainer-analytics {
    padding-top: 0px;
}

.vendorDashboard__stateSaleProjections {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 2000px;
}

.vendorDashboard__stateSaleProjections-header,
svg.vendorDashboard__stateSaleProjections-header-icon {
    font-size: clamp(1.5rem, 2.5vw, 2.1rem);
    vertical-align: middle;
}

.vendorDashboard__gridContainer-vendorBankAccounts .div__detail-item.vendorDashboard__gridContainer--bankAccountsList {
    height: 540px;
    width: 98%;
    border-radius: 8px;
    margin: 10px;
}

.vendorDashboard__gridContainer-vendorBankAccounts .vendorDashboard__gridContainer--bankAccountsList .vendorDashboard__gridContainer--bankAccountsList-header {
    border-radius: 8px 8px 0px 0px;
}

.vendorDashboard__gridContainer .MuiGrid-root {
    padding: 0px;
}

.div__detail-item.analytics__card{
    height: 170px;
    width: auto;
    border: 0px;
}

.vendorDashboard .div__detail-item {
    border: 0px;
}
