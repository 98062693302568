.netBanking__form {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.netBanking__form--topBanks {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.netBanking__form--bank {
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 8px;
    margin: 10px 0px;
    width: 40%;
    cursor: pointer;
}

.netBanking__form--bank-image {
    width: 100%;
    aspect-ratio: 44/11;
}

.netBanking__form--otherBanks {
    width: 40%;
}

.netBanking__form--otherBanks {
    margin: 20px 0px;
}

.netBanking__form .div__dropdown-input{
    width: 100%;
}
.netBanking__form .div__dropdown-input .MuiOutlinedInput-root.MuiInputBase-root{
    width: 100%;
}

#menu- .MuiMenu-list{
    display: flex;
    flex-direction: column;
}