.map__India__Container{
    width: 100%;
    display: flex;
    width: 80%;
    height: 1420px;
    margin: auto;
    margin-top: 50px;
}

.map__India{
    width: 70%;
    height: 1420px;
    margin: auto;
    margin-top: 50px;
}