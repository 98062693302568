.mobile .div__landingPage-topLanguages,
.mobile .div__landingPage-latestAdditions {
  letter-spacing: 2px;
  height: auto;
  padding: 10px;
}
.mobile .imgBanner {
  aspect-ratio: 1920/1000;
  width: 100%;
}

.mobile .div__landingPage-latestAdditions-list {
  width: 100%;
  justify-content: center;
}