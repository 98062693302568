.replyTicket-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.replyTicket-container__heading {
  font-size: clamp(1.5rem, 1.8vw, 2.5rem);
  font-weight: normal;
  line-height: 1.17;
  color: #585858;
  text-transform: uppercase;
}

.replyTicket-container__view {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.replyTicket-container__leftGallery{
    width: 30%;
}

.replyTicket-container__leftGallery .div__detail-item{
    width: 100%;
    margin: 0px;
}

.replyTicket-container__rightGallery .div__detail-item{
    margin: 0px;
}

.replyTicket-container__rightGallery .txtAreaInput.replyTicket-container__comment{
    margin : 0px;
}

.replyTicket-container__rightGallery{
    display: flex;
    flex-direction: column;
    width: 60%;
}

.div__detail-item.replyTicket-container__leftGallery-ticketImages {
    margin:0px;
}

.replyTicket-container__leftGallery-ticketImages .replyTicket-container__leftGallery-ticketImages-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 415px;
    overflow-y: auto;
    justify-content: center;
}

.replyTicket-container__leftGallery-ticketImages .replyTicket-container__leftGallery-ticketImages-content::-webkit-scrollbar {
    display: none;
}
  
.replyTicket-container__leftGallery-ticketImages .replyTicket-container__leftGallery-ticketImages-content {
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.replyTicket-container__leftGallery-noAttachments {
    font-size: clamp(1rem,1.5vw,1.8rem);
    color: #707070;
    text-align: center;
}

.replyTicket-container__leftGallery-ticketImages .replyTicket-container__leftGallery-ticketImages-header {
    padding: 10px;
}

.replyTicket-container__leftGallery-ticketImage {
    width: 493px;
    min-height: 237px;
    margin: 21px 26px 32px 32px;
    border-radius: 29px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.replyTicket-container__rightGallery-ticketDetails-header {
    display: flex;
    justify-content: space-between;
}

.replyTicket-container__rightGallery-ticketDetails-content {
    display: flex;
    flex-direction: column;
}

.replyTicket-container__rightGallery-ticketDetails-reply {
    margin: 30px 10px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.replyTicket-container__rightGallery-ticketDetails-replyHeader,
.replyTicket-container__rightGallery-ticketDetails-replyContent {
    color: #585858;
    font-size: 24px;
}

.replyTicket-container__rightGallery-ticketDetails-replyHeader {
    color: #707070;
    font-size: 32px;
}

.replyTicket-container__actionButtons .replyTicket-container__actionButtons-resolve,
.replyTicket-container__actionButtons .replyTicket-container__actionButtons-resolve:hover {
    width: 40%;
    height: 65px;
    border-radius: 10px;
    background-color: #86c232;
    color: white;
    font-size: clamp(1rem,1.5vw,1.8rem);
    text-transform: uppercase;
}

.replyTicket-container__actionButtons .replyTicket-container__actionButtons-cancel,
.replyTicket-container__actionButtons .replyTicket-container__actionButtons-cancel:hover {
    width: 40%;
    height: 65px;
    border-radius: 10px;
    border: solid 3px #86c232;
    color: #86c232;
    font-size: clamp(1rem,1.5vw,1.8rem);
}

.replyTicket-container span.errorMessage {
    color: crimson !important; 
    font-size: 20px !important;
    margin-bottom: 5px;
}

.replyTicket-container .lblInput{
    font-size: clamp(1rem,1.5vw,1.8rem);
}

.div__detail-item-header p {
    font-size: clamp(0.8rem,1vw,1.5rem);
}

.txtAreaInput.replyTicket-container__comment {
    width: 96%;
    height: 156px;
    margin: 10px;
    border-radius: 11px;
    border: solid 1px #a0a0a0;
    font-size: 24px;
}

.replyTicket-container__actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.replyTicket-container__rightGallery-ticketDetails-content .rowDetail__container .rowDetail__label, 
.replyTicket-container__rightGallery-ticketDetails-content .rowDetail__container .rowDetail__description {
    font-size: clamp(1rem,1.5vw,1.8rem);
}

.replyTicket-container__actionButtons{
    width: 100%;
}