.tabletLandscape .myTickets__filters-selectCategory {
    padding: 4px;
    width: 150px;
    height: 50px;
    background-color: white;
    color: #686868;
    margin: 1px;
  }

  .tabletPortrait .myTickets__filters-selectCategory {
    padding: 4px;
    width: 120px;
    height: 58px;
    background-color: white;
    color: #686868;
    margin: 1px;
  }
