.tabletLandscape .imgLogoMemeT {
  height: 150px;
  width: 150px;
  margin-top: -30px;
  margin-left: 10px;
}
.tabletPortrait .imgLogoMemeT {
  height: 150px;
  width: 150px;
  margin-top: -30px;
  margin-left: 10px;
} 
.tabletLandscape .div__footer-contactUs-details {
  margin-top: 70px;
}
.tabletPortrait .div__footer-contactUs-details {
  margin-top: 70px;
}
.tabletPortrait .div__footer-pagesList {
  margin-left: 10px;
}

.tabletPortrait .div__footer-languagesList {
  margin-right: 20px;
}
.tabletLandscape .lnkSocialMedia {
  height: 60px;
  width: 60px;
  margin-left: 10px;
  margin-top: 25px;
}
.tabletPortrait .lnkSocialMedia {
  height: 60px;
  width: 60px;
  margin-left: 10px;
  margin-top: 25px;
}
