.MuiTab-root.MuiTab-textColorPrimary {
    color: white;
    text-transform: none;
    font-size: clamp(1rem, 1.7vh,2rem);
}

.MuiTabs-flexContainer {
    justify-content: flex-start;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: #86c238;
}

.appContainer .MuiTabs-indicator {
    background-color: #86c238;
}