.ticket__detail-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px 10px;
}

.ticket__detail--left {
    width:40%;
    height: 100%;
}

.ticket__detail--left .orderDetail__content--left-item-header {
    padding: 15px;
}

.ticket_detail--right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ticket__detail--left .div__detail-item-content {
    height: 500px;
    overflow-y: auto;
}

.ticket__detail--left .div__detail-item-content::-webkit-scrollbar {
    display: none;
}
  
.ticket__detail--left .div__detail-item-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ticket__detail-imagesList_image {
    height: 237px;
    width: 493px;
    border: 1px solid #707070;
    border-radius: 8px;
    margin: 10px;
}

.ticket__detail-container-header {
    color: #585858;
    font-size: clamp(1rem, 1.5vw, 2.5rem);
    align-self: center;
}

.ticket__detail-container-content {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}

.ticket_detail-content {
    width: 100%;
}

.ticket__detail--right-category {
    color: #929292;
    font-weight: bold;
    align-self: flex-start;
    font-size: clamp(1rem, 1.5vw, 2.5rem);
    width: 100%;
}

.ticket__detail--right-category .rowDetail__label {
    color: #929292;
    flex: 0.3;
    font-size: clamp(0.9rem,1.5vw,2rem);
}

.ticket__detail--right-category .rowDetail__description {
    text-transform: uppercase;
}

.ticket__detail--right-imagesList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.ticket__detail--right .ticket__detail--right-images-header {
    padding: 10px;
}

.ticket__detail--right-imagesList_image {
    width: 217px;
    height: 152px;
    margin: 10px;
} 

.ticket__detail--right-details-titleLabel,
.ticket__detail--right-details-replyLabel,
.ticket__detail--right-details-titleDescription,
.ticket__detail--right-details-replyDescription {
    margin: 10px;
}

.ticket__detail--right-details-titleLabel,
.ticket__detail--right-details-replyLabel {
    font-size:clamp(1.3rem, 1.5vw, 2rem);
    color: #b7b7b7;
}

.ticket__detail--right-details-titleDescription,
.ticket__detail--right-details-replyDescription {
    font-size: clamp(1rem, 1.5vw, 2.5rem);
    color: #797979;
}

.ticket__detail--right-details-replyPlaceholder {
    color: #b7b7b7;
    font-size: clamp(1rem, 1.5vw, 2.5rem);
}
.ticket__detail--right{
    width: 100%;
    margin: 0px 20px;
}

.ticket__detail--left{
    margin-left: 20px;
}

@media (max-width : 920px) {

    .ticket__detail--right{
        margin: 0px;
        margin-right: 22px;
        display: flex;
        flex-direction: column;
    }

    .ticket__detail-container-content {
        flex-direction: column;
        align-items: center;
    }

    .div__detail-item.orderDetail__content--left-items {
        margin: 10px 0px;
    }

    .drawerMenu.MuiDrawer-root.myTickets-ticketDrawer--add .MuiDrawer-paper,
    .drawerMenu.MuiDrawer-root.myTickets-ticketDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
    }

    .drawerMenu.MuiDrawer-root.myTickets-ticketDrawer--add .MuiDrawer-paper .btnCloseDrawer .MuiSvgIcon-root,
    .drawerMenu.MuiDrawer-root.myTickets-ticketDrawer--view .MuiDrawer-paper .btnCloseDrawer .MuiSvgIcon-root {
        margin-top: 20px
    }

    .ticket__detail--right-category .rowDetail__label {
        flex: 0.4;
    }
    
}

@media (max-width : 845px) {
    
    .ticket__detail--left{
        order: 2;
        margin-left: 0px;
    }

    .ticket__detail--right{
        order: 1;
    }

    .ticket__detail-container-content{
        flex-direction: column-reverse;
    }

    .div__detail-item.ticket__detail--right-images,
    .orderDetail__content--left-items {
        width: 100%;
    }

    .ticket__detail--left{
        width: 100%;
    }
}