.mobile .div__productDetail-ratings{
    margin: 100px 0px;
}

.mobile .div__productDetail-ratings-list {
    padding: 15px;
}

.mobile .div__productDetail-ratings-feedback{
    display: none;
}

.mobile .div__productDetail-ratings{
    justify-content: center;
}

.mobile .div__feedbackDetail-userRating {
    flex-basis: 30%;
}