.div__dropdown-input {
    display: flex;
    flex-direction: column;
}

.div__dropdown-input .lblInput {
    font-size: clamp(1rem,1.5vw,1.5rem);
}

#dropdownInput {
    padding: 0px;
    text-align: center;
    width: 100%;
}
  
#dropdownInput .MuiSelect-icon.MuiSelect-iconOutlined {
    color: black;
}

.MuiList-root li {
    font-size: clamp(0.8rem,1.1vw,1.5rem);
}
