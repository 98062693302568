.div__productDetail-ratings {
    display: flex;
    justify-content: space-between;
    margin: 50px 10px;
}

.div__productDetail-ratings-list, .div__productDetail-ratings-feedback {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    padding: 30px;
}

.div__productDetail-ratings-list-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 10px;
    padding: 5px;
    width: 100%;
}

.div__productDetail-ratings-list-composition {
    margin: 0px 10px;
    width: 66%;
}

.iconComposition {
    margin-bottom: -5px;
    height: 20px;
}

.div__ratings-composition {
    display: flex;
    align-items: center;
    margin: 5px 0px;
}

.div__ratings-percentageContainer {
    width: 150px;
    height: 5px;
    background: grey;
    margin: 0px 10px;
    border-radius: 5px;
}

.div__ratings-percentageFiller {
    height: 5px;
    background: white;
    border-radius: 5px;
}

.div__productDetail-ratings-images, .div__productDetail-ratings-reviews {
    margin: 10px;
    padding: 10px 0px;
}

.div__productDetail-ratings-reviews {
    height: 700px;
}

.div__productDetail-ratings-imagesList {
    display: flex;
    justify-content: space-around;
    margin: 8px -15px;
}

.div__productDetail-imagesList .imageItem,
.div__productDetail-ratings-imagesList .imageItem {
    width: 100px;
    height: 100px;
}

.ratingHeader {
    font-size: clamp(1.2rem,2vw,2rem);
}

.iconRatingsHeader {
    margin-bottom: -5px;
    margin-left: 15px;
}

.iconRatingsHeader, .iconsRatingHeader, .iconAverageRating {
    height: 30px;
    width: 30px;
}

.iconsRatingHeader {
    margin-bottom: -5px;
}

.iconIndividualRating {
    height: 20px;
    margin-bottom: -4px;
}

.averageRating {
    font-size: clamp(1.5rem,4vw,2.5rem);
}

.div__feedbackDetail .individualRating {
    margin-right: 5px;
    margin-bottom: -10px; 
    font-size: 19px;
}

.verticalDivider {
  width: 0px;
  height: 130px;
  margin: 5px;
  border: solid 1px #707070;
}

.horizontalDivider {
    width: 100%;
    height: 0px;
    margin: 5px;
    border: 0.5px solid #707070;
}

.div__productDetail-ratings-reviewsList {
    display: flex;
    flex-direction: column;
    margin: 15px 5px;
}

.div__productDetail-ratingFeedback-imagesList {
    display: flex;
    justify-content: flex-start;
}

.div__productDetail-ratingFeedback-imagesList .div-uploadedImage {
    margin: 5px 10px;
}

.div__feedbackDetail-userRating {
    flex-basis: 8%;
}

.div__feedbackDetail-userFeedback {
    width: 100%;
}

.div__feedbackDetail {
    padding: 15px;
}

.div__feedbackDetail, .feedbackDetail, .feedbackAuthor {
    margin-bottom: 15px;
    display: flex;
}

.feedbackDetail {
    margin: 10px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.div__feedbackDetailSelected {
    background-color: #383939;
    border-radius: 10px;
}

.feedbackAuthor {
    color: rgb(181, 181, 181);
    margin: 10px 0px;
    font-size: clamp(0.8rem,1.15vw,2rem);
}

.lnkViewAllRatings {
    color: #86c232;
    margin: 10px;
    cursor: pointer;
}

.feedbackImagePreview {
    width: 667px;
    height: 449px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #707070;
    border-radius: 26px;
    margin: 10px 0px;
    color: white;
}

.feedbackDate {
    margin: 0px 100px;
}

.div__ratingValue {
    margin: 10px 0px;
}

.div__ratingValue p {
    margin: 10px 0px;
    font-size: clamp(0.8rem,1.4vw,2rem);
    color: lightgray
}