.label__cardNumber, .label__cardNumber-subheading,
.label__HolderName,.label__HolderName-subheading,
.label__CVV, .label__ExpiryDate{
    color: #222629;
    margin: 10px 0px;
    font-size: clamp(0.8rem,1vw,2rem);
}

.label__cardNumber,.label__HolderName,
.label__CVV, .label__ExpiryDate{
    font-weight: 1000;
}

.cardPayment__container, .cardPayment__CardNumber,
.cardPayment__HolderName, .cardPayment__CVV-ExpiryDate__Container{
    width: 100%;
}

.cardPayment__CardNumber .MuiFormControl-root,
.cardPayment__HolderName .MuiFormControl-root
{
    width: 100%;
}

.cardPayment__CVV-ExpiryDate__Container{
    display: flex;
    width: 60%;
}

#button_checkoutPayment{
    width: 100%;
    background: #222629;
    color: rgb(255, 255, 255);
    height: 70px;
    border-radius: 5px;
    margin: 30px 0px;
    font-size: clamp(0.9rem, 1.2vw, 2rem);
}

.cardPayment__ExpiryDate{
    margin: 0px 20px;
}

.ExpiryDate__DateInput{
    display: flex;
    align-items: center;
}