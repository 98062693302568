.MuiFormControlLabel-label {
    color: #86c232;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    color: lightgray;
}

span.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked {
    color: white;
}