.mobile .div__aboutUs-languages-languageGraph {
    width: 387px;
    height: 172px;
    margin: 30px auto;
    object-fit: cover;
}

.mobile .div__aboutUs-USP-imgPlatformUSP {
    width: 378px;
    height: 154px;
    margin: 30px auto;
    object-fit: cover;
}

.mobile .div__aboutUs-Designer-imgDesigner {
    width: 278px;
    height: 268px;
    margin: 30px auto;
    object-fit: cover;
}
