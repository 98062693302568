.vendorDashboard__gridContainer--bankAccountsList .vendorDashboard__gridContainer--bankAccountsList-header {
    background-color: #212628;
    font-size: clamp(1rem, 1.2vw, 1.8rem);
    padding: 15px 30px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 23px;
    border-bottom: 1px solid #707070;
    border-radius: 0px;
}

.vendorDashboard__gridContainer--bankAccountsList-header-viewAllTickets {
    color: #b4ff4f;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
}

.div__detail-item.vendorDashboard__gridContainer--bankAccountsList {
    height: 100%;
    margin: 0px;
    width: 100%;
    border-radius: 0px;
}

.vendorDashboard__gridContainer--bankAccountsList .vendorDashboard__gridContainer--bankAccountsList-icon {
    vertical-align: middle;
    width: 30px;
    height: 30px;
}

.vendorDashboard__gridContainer--bankAccountsList .vendorDashboard__gridContainer--bankAccountsList-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #212628;
    height: 100%;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;
    padding: 0px;
}

.vendorDashboard__gridContainer--bankAccountsList .vendorDashboard__gridContainer--bankAccountsList-content::-webkit-scrollbar {
    display: none;
}
  
.vendorDashboard__gridContainer--bankAccountsList .vendorDashboard__gridContainer--bankAccountsList-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.vendorDashboard__gridContainer--bankAccountsList-imgEmptyList {
    width: 435px;
    height: 200px;
}

.vendorDashboard__gridContainer--bankAccountsList-content .gridContent__emptyList-message {
    width: 610px;
    font-size: clamp(1rem,1.2vw,1.8rem);
    text-align: center;
    display: flex;
    width: 50%;
}