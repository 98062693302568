.mobile .admin__ordersList {
    padding: 10px 15px;
}

.mobile .admin__ordersList__filters{
    flex-direction: column;
}

.mobile .div__searchInput{
    width: 100%;
}

.mobile .div__searchInput{
    width: 100%;
}

.mobile .div__Filters{
    width: 105%;
    flex-wrap: wrap;
    justify-content: space-around;
} 

.mobile .div__dateRangePicker-container,
.mobile .div__dropdown-input{
    width: 44%;
    padding: 10px;
}

@media (max-width : 500px) {

    .orderDetail__container-content--right-vendorActions{
        width: 90%;
    }

}

@media (max-width : 600px) {

    .orderDetail__content--left-item-image {
        width: 30%;
        aspect-ratio: 320/437;
        border-radius: 8px;
    }

    .MuiDrawer-root.admin__ordersList__orderDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
    }

}



