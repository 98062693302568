.memeSuggestion__detail-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.memeSuggestion__detail--left {
    width: 35%;
    height: 500px;
}

.memeSuggestion_detail--right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.memeSuggestion__detail--left .div__detail-item-content {
    height: 500px;
    overflow-y: auto;
}

.memeSuggestion__detail--left .div__detail-item-content::-webkit-scrollbar {
    display: none;
}
  
.memeSuggestion__detail--left .div__detail-item-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.memeSuggestion__detail-imagesList_image {
    height: 237px;
    width: 493px;
    border: 1px solid #707070;
    border-radius: 8px;
    margin: 10px;
}

.memeSuggestion__detail-container-header {
    color: #585858;
    font-size: clamp(1.2rem, 2vw, 2rem);
    align-self: center;
}

.memeSuggestion__detail-container-content {
    width: 100%;
    display: flex;
    justify-content: center;
}

.memeSuggestion_detail-content {
    width: 100%;
}

.memeSuggestion__detail-content-list {
    width: 100%;
}

#btnInput.memeSuggestion_detail-withdrawMeme {
    font-size: clamp(1rem, 1.8vw, 2rem);
    background-color: #74a730;
    width: clamp(12rem, 20vw, 67rem);
}
.memeSuggestion__detail-content-list .rowDetail__label,
.memeSuggestion__detail-content-list .rowDetail__description{
    font-size: clamp(1rem, 2vw, 2rem);
}

.memeSuggestion__detail-imagesList{
    width: 100%;
}
.memeSuggestion__detail-container .memeSuggestion__detail-imagesList_image{
    width: 100%;
    margin: 0px;
}

.memeSuggestion__detail-container .div__detail-item-header {
    padding-left: 10px ;
    height: auto;
    font-size: clamp(1rem, 1.5vw, 1.8rem);
}

@media (max-width: 670px) {
    .memeSuggestion__detail-container-content{
        flex-direction: column;
    }
    .memeSuggestion__detail--left ,.memeSuggestion_detail--right{
        width: 100%;
    }
    .memeSuggestion_detail--right{
        margin:0px;
    }
    
}