.userCart__addressSelected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.userCart__addressSelected-recipientName,
.userCart__addressSelected-recipientContact,
.userCart__addressSelected-recipientAddress {
  margin: 10px 15px;
  width: 80%;
}

.userCart__addressSelected-recipientName {
  font-size: clamp(1rem, 1.5vw, 3rem);
}

.userCart__addressSelected-recipientAddress,
.userCart__addressSelected-recipientContact {
  font-size: clamp(1rem, 1vw, 2rem);
}

#btnInput.userCart__addressSelected-changeAddress {
  width: 95%;
  height: 50px;
  margin: 10px;
  font-size: clamp(0.9rem, 1vw, 2rem);
}

.MuiDrawer-root.addressManagement__drawer .MuiDrawer-paper {
  height: 80%;
}

.MuiDrawer-root.addressManagement__drawer .btnCloseDrawer svg {
  color: #707070;
}
