.mobile .admin__ticketsList__filters{
    flex-direction: column;
}

.mobile .div__searchInput{
    width: 100%;
}

.mobile .div__searchInput{
    width: 100%;
}

.mobile .div__Filters{
    width: 109%;
    flex-wrap: wrap;
    justify-content: space-around;
} 

.mobile .div__dateRangePicker-container,
.mobile .div__dropdown-input{
    width: 36%;
    padding: 10px;
}