.mobile .div__searchProducts-header{
    margin-top: 20px;
}

.mobile .div__searchProducts-results-list {
    justify-content: center;
}

@media (max-width: 500px) {

    #sortMenu .MuiMenu-paper {
        width: 180px;
    } 
}