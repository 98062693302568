.App {
  text-align: center;
}

input::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.appContainer {
  background-color: #585858;
  height: 100%;
}

#routesContainer {
  padding-top: 70px;
  height: 100%;
}

* {
  font-family: Helvetica;
}
