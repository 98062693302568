.addressForm-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.addressForm-header {
    font-size: clamp(1.2rem,1.6vw,2.2rem);
    border-bottom: 4px solid #74a730;
    width: 25%;
}

.addressForm-container-details {
    width: 100%;
    margin-top: 15px;
}

.addressForm-container-detail-locality,
.addressForm-container-detail-contact {
    display: flex;
    justify-content: space-between;

}

.addressForm-container-detail-locality .div__txtInput,
.addressForm-container-detail-contact .div__txtInput{
    width: 48%;
}

.addressForm-container .lblInput {
    color: #a0a0a0;
}

#btnInput.addressForm-container-submitForm,
#btnInput.addressForm-container-cancelForm {
    width: 45%;
    height: 60px;
    align-self: center;
    margin: auto;
}

#btnInput.addressForm-container-cancelForm {
    background-color: white;
    color: #74a730;
    border: 1px solid #74a730;
}

.addressForm-container-buttons {
    display: flex;
    justify-content: space-around;
}