.div__detail-item,
.div__detail-item-header,
.div__detail-item-content,
.div__detail-item-actions {
    display: flex;
}

.div__detail-item {
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #707070;
    margin: 10px;
}

.div__detail-item-header {
    background-color: #a0a0a0;
    color: white;
    font-weight: bold;
    height: auto;
    padding: 10px 0px;
    border-radius: 7px 7px 0px 0px;
}

.div__detail-item-header p {
    margin: 0px 10px;
}

.div__detail-item-content {
    padding: 10px 20px;
    justify-content: space-between;
}

.div__detail-item .horizontalDivider {
    width: 95%;
    margin: 0px 5px;
    align-self: center;
}

.div__detail-item div.verticalDivider {
    height: 50px;
    border-width: 0.5px;
    width: 0px;
}

.div__detail-item-actions div {
    color: #86c232;
    width: 100%;
    cursor: pointer;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div__detail-item-actions svg {
    color: white;
}

.div__detail-item-actions span {
    margin: 0px 5px;
}