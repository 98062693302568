.product__inventoryList {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.product__inventoryList-header {
    font-size: clamp(1rem, 1.5vw, 2rem);
}

.product__inventoryList .messageTitle{
    font-size: clamp(1rem, 1.5vw, 2rem);
}

.product__inventoryList .messageDescription{
    font-size: clamp(1rem,1.2vw,2rem);
}