.div__memeSuggestionForm-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.formHeader {
    font-size: clamp(1.2rem,2vw,2rem);
    margin: 20px;
}

.div__memeSuggestionForm-formContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.div__memeSuggestionForm-formContainer-details {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
}

.div__memeSuggestionForm-formContainer-primary {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.div__memeSuggestionForm-formContainer-primary .div__txtInput {
    width: 100%;
    max-width: 330px;
}

.div__memeSuggestionForm-formContainer .autoCompleteLanguage {
    padding: 0px;
    background-color: white;
    color: black;
    height: 37px;
    width: 100%;
    max-width: 330px;
    margin: 0px 5px;
    border-radius: 8px;
}

.div__memeSuggestionForm-container-imagesUpload {
    display: flex;
}

.div__detail-item .memeSuggestion__detail--left{
    width: 40%;
}
.memeSuggestion_detail--right{
    width: 60%;
    margin: 0px 20px;
}

.div__memeSuggestionForm-formContainer .lblInput{
    text-align: left;
    color: white;
    font-size: clamp(1rem,1.5vw,1.8rem);
}

.div__txtInput , .div__autoComplete-input{
    width: 100%;
}

.div__memeSuggestionForm-formContainer-imagesUpload{
    width: 58%;
    margin-left: 2%;
}


.div__memeSuggestionForm-formContainer .txtAreaInput .txtMemeDescription{
    width: 100%;
}


.div__memeSuggestionForm-formContainer .div__imagePreview-container{
    width: 100%;
}

.div__imagePreview{
    width: 100%;
}

.div__memeSuggestionForm-formContainer-imagesUpload .div__imagePreview{
    margin: 0px;
}

.div__memeSuggestionForm-formContainer .imgDetailPreview,
.div__memeSuggestionForm-formContainer-imagesUpload .imgCardPreview{
    width: 100% ;
}

.div_displayImage{
    width: 50%;
    margin: 10px;
}

.div__memeSuggestionForm-formContainer .div__imagePreview-container{
    margin: 0px;
}

.div__memeSuggestionForm-formContainer .imgDetailPreview{
    height: 280px;
}

@media (max-width: 730px) {

    .div__memeSuggestionForm-formContainer{
        flex-direction: column !important;
    }
    .div__memeSuggestionForm-formContainer-details{
        order: 2 !important;
        width: 100% !important;
    }
    .div__memeSuggestionForm-formContainer-imagesUpload{
        order: 1 !important;
        width: 100% !important;
    }
    
}