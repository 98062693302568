.gridContent__emptyList {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
  margin: 10px 0px;
}

.gridContent__emptyList-message,
.gridContent__emptyList-subMessage {
  margin-top: 10px;
}

.gridContent__emptyList-message {
    font-size: clamp(1.2rem, 1.2vw, 2rem);
}

.gridContent__emptyList-subMessage {
    font-size: clamp(0.9rem, 1.2vw, 2rem);
}

#btnInput.gridContent__emptyList-button {
  background: #86c232;
  color: white;
  align-self: center;
  text-transform: none;
  font-weight: bold;
  font-size: 18px;
  margin-top: 30px;
}

@media (max-width: 600px) {
  .gridContent__emptyList-message .gridContent__emptyList-subMessage {
    font-size: 18px;
  }
}
