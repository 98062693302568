.div__userCart-priceSummary {
    display: flex;
    flex-direction: column;
    width: 95%;
}

.div__userCart-priceSummary-header {
    background-color: #6f6f6f;
    margin: 10px 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.div__userCart-priceSummary-details {
    display: flex;
    justify-content: space-between;
}

.tblPricingSummary {
    margin: 5px;
    color: #d6d6d6;
}

.tblPricingSummary td {
    padding: 10px;
}

.tblPricingSummary td:nth-child(2) {
    text-align: end;
}

.tblPricingSummary tr:last-child {
    font-size: 20px;
}

.div__userCart-priceSummary .horizontalDivider {
    width: 135%;
}