.admin__ordersList {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.admin__ordersList-header,
.admin__ordersList-header svg {
    font-size: clamp(2rem, 2vw, 2.5rem);
    margin: 20px 0px;
    vertical-align: middle;
}

.admin__ordersList__filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.admin__ordersList__searchInput {
    width: 100%;
    height: 50px;
}

.admin__ordersList__searchInput input {
    font-size: clamp(1rem, 1.2vw, 1.8rem);
    padding: 10px;
}

.admin__ordersList__fromDate .MuiOutlinedInput-root.MuiInputBase-root,
.admin__ordersList__toDate .MuiOutlinedInput-root.MuiInputBase-root {
    background-color: #212628;
    color: white;
    font-size: clamp(0.8rem,1vw,1.5rem);
    width: 100%;
    height: 45px;
}

.div__dropdown-input .admin__ordersList__orderStatus.MuiOutlinedInput-root.MuiInputBase-root,
.div__dropdown-input .admin__ordersList__deliveryStatus.MuiOutlinedInput-root.MuiInputBase-root {
    background: #212628;
    color: white;
    width: 100%;
    height: 45px;
    font-size: clamp(0.8rem,1vw,1.5rem);
    text-align: left;
}

.admin__ordersList__orderStatus .MuiSelect-icon.MuiSelect-iconOutlined,
.admin__ordersList__deliveryStatus .MuiSelect-icon.MuiSelect-iconOutlined {
    color: white;
}

.admin__ordersList__fromDate svg,
.admin__ordersList__toDate svg {
    color: white;
}

.MuiList-root {
    background-color: #212628;
    color: white;
}

.MuiList-root li {
    font-size: clamp(1rem,1vw,1.5rem);
}

.MuiDrawer-root.admin__ordersList__orderDrawer--view .MuiDrawer-paper {
    height: 90%;
}

.MuiDrawer-root.admin__ordersList__orderDrawer--view .btnCloseDrawer svg {
    color: #707070;
}

.admin__ordersList__filters .div__Filters{
    display: flex;
}

.div__Filters .div__dateRangePicker-container, .div__dropdown-input{
    width: 30%;
    padding: 0px 5px;
}

.div__searchInput{
    width: 40%;
}

.div__Filters{
    width: 60%;
    margin: 15px 0px;
}

.admin__ordersList .MuiTablePagination-selectLabel,.admin__ordersList .MuiTablePagination-selectIcon, .admin__ordersList .MuiTablePagination-select.MuiSelect-select, .admin__ordersList .MuiTablePagination-displayedRows, .admin__ordersList .MuiTablePagination-actions .MuiSvgIcon-root{
    font-size: clamp(1rem,1vw,1.5rem) !important; 
}


.admin__ordersList .div__tableList-container th.headerColumn, .div__tableList-container tr.dataRow td, .div__tableList-container .noDataRow{
    font-size: clamp(1rem,1vw,1.5rem) !important;
}

.orderDetail__container-header{
    font-size: clamp(1.2rem,1.7vw,2rem);
}

.orderDetail__container{
    width: 100%;
}

.orderDetail__container-content{
    width: 100%;
    justify-content: space-between;
}

.orderDetail__container-content--left{
    width: 35%;
}

.orderDetail__content--left-items{
    width: 100%;
}

.orderDetail__container-content--right{
    width: 60%;
}

.orderDetail__container-content--right-bottom{
    /* flex-direction: column; */
    align-items: center;
}

.orderDetail__container-content--right-recipientDetails{
    width: 100%;
}

.orderDetail__content--left-item-content {
    width: 50%;
}

.orderDetail__container-content--right-item .rowDetail__label {
    padding: 5px;
    font-size: clamp(0.8rem, 1.2vw, 2rem);
}

.rowDetail__container .orderDetail__content--left-item-content-value .orderDetail__content--left-item-header{
    font-size: clamp(0.8rem, 1.2vw, 2rem);
}
.orderDetail__container .orderDetail__container-content--left-item-header, 
.orderDetail__container .orderDetail__container-content--right-item-header{
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    align-items: center;
}

.orderDetail__container-content .rowDetail__description,
.orderDetail__container-content .rowDetail__label
{
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    padding: 5px;
}
.orderDetail__container-content--right-vendorActions{
    width: 60%;
}

.orderDetail__container-content--right-vendorActions .orderDetail__container-content--right-vendorActions-invoice{
    width: 100%;
    font-size:clamp(1rem, 1.2vw, 1.8rem) ;
}