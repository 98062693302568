.mobile .myOrders__container {
  padding: 10px 10px;
}

.mobile .div__detail-item {
  margin: 20px 0px;
}

.mobile .div__detail-item.myOrder__card {
  width: 95%;
  margin: 20px auto;
}

.mobile .MuiOutlinedInput-root.MuiInputBase-root {
  height: 45px;
}

.mobile .myOrdersList__searchInput {
  width: 100%;
}

.mobile .myOrder__card .div__detail-item-content{
  padding: 0px;
}
