.div__userWishlist-container,
.div__userWishlist-header {
    display: flex;
}

.div__userWishlist-container {
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;
}

.div__userWishlist-header {
    justify-content: flex-start;
    margin-left: 5px;
    margin: 10px;
    align-items: center;
}

.div__userWishlist-header .iconFavorite {
    color: #86c232;
    font-size: clamp(1.5rem,1.8vw,2.2rem);
    margin-right: 10px;
}

.lblWishlist {
    color: #a9a9a9;
    font-size: clamp(1.5rem,1.5vw, 2rem);
    display: inline-block;
    align-self: center;
}

.div__userWishlist-results-list {
    width: 100%;
    height: 100%;
    display: flex;
}

.div__userWishlist-emptyWishlist {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #707070;
    padding: 20px 0px;
}

.imgEmptyWishlist {
    width: 30%;
    aspect-ratio: 447/322;
}

.lblEmptyWishlistMessage, .lblEmptyWishlistMessage2 {
    margin: 20px 0px;
}

.lblEmptyWishlistMessage {
    font-size: 30px;
}

.lblEmptyWishlistMessage2 {
    font-size: 22px;
}

.div__userWishlist-container .btnContinueShopping,
.div__userWishlist-container .btnContinueShopping:hover {
    background: #86c232;
    color: white;
    align-self: center;
    text-transform: none;
    font-weight: bold;
    font-size: 18px;
}

.div__userWishlist-recommendedItems {
    margin: 10px -10px;
}

.div__userWishlist-container .gridContent__emptyList-message{
    font-size: clamp(1rem,1.5vw,2rem);
}

.div__userWishlist-container .gridContent__emptyList-subMessage{
    font-size: clamp(1rem,1.3vw,2rem);
}