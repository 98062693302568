.div__cart-item,
.div__cart-item-header,
.div__cart-item-content,
.div__cart-item-detail,
.div__cart-item-inventoryDetail,
.div__cart-item-inventoryQuantity,
.div__cart-item-actions {
  display: flex;
}
.div__cart-item-inventoryDetail {
  align-items: center;
  gap: 01%;
  flex-wrap: wrap;
  color: #d6d6d6;
  justify-content: space-between;
  font-size: clamp(1rem, 1.2vw, 1.5rem);
  margin: 5px 0px;
}

.div__cart-item {
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.27);
  margin-top: 20px;
  width: 100%;
  height: auto;
}

.div__cart-item-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #a0a0a0;
  height: 30px;
  padding: 5px;
  border-radius: 7px 7px 0px 0px;
}

.div__cart-item-header p {
  margin: 0px 10px;
}

.div__cart-item-content {
  padding: 10px 20px;
  justify-content: space-between;
  cursor: pointer;
}

.div__cart-item-detail {
  flex-direction: column;
  justify-content: space-around;
  width: 48%;
}

.div__cart-item-detail p {
  margin: 2% 0px;
}

.div__cart-item-inventoryDetail-color {
  display: flex;
  align-items: center;
}

.div__cart-item-inventoryDetail-color-circle {
  width: clamp(20px, 1.2vw, 37px);;
  height: clamp(20px, 1.2vw, 37px);;
  border-radius: 50%;
  margin: 0px 0.5rem;
}

.div__cart-item-detail-title {
  font-size: clamp(1rem, 1.5vw, 2rem);
}

.div__cart-item-detail-discountedPrice {
  font-size: clamp(1rem, 1.5vw, 2rem);
  margin-right: 8px;
}

.div__cart-item-detail-actualPrice {
  font-size: clamp(1rem, 1.2vw, 2rem);
  display: inline-block;
  text-decoration: line-through;
  margin: 0px 8px;
}

.div__cart-item-detail-discount {
  color: #86c232;
  font-size: clamp(1rem, 1.2vw, 2rem);
}

.div__cart-item-detail-itemQuantity {
  margin-left: 20px;
  font-size: clamp(1rem, 1.2vw, 1.5rem);
}

.horizontalDivider {
  width: 95%;
  margin: 0px auto;
  margin-top: 10px !important;
}

.div__cart-item div.verticalDivider {
  height: 60px;
  border-width: 0.5px;
  width: 0px;
}

.div__cart-item-actions div {
  color: #86c232;
  width: 100%;
  cursor: pointer;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div__cart-item-actions {
  align-items: center;
}

.div__cart-item-actions .iconWishlist,
.div__cart-item-actions .iconWishlistSelected {
  color: white;
  border: none ;
  background: transparent;
}

.div__cart-item-actions div{
  font-size: clamp(0.5rem, 1.5vw, 2rem);
}

.div__cart-item-actions span {
  margin: 0px 5px;
}

.div__cart-item-actions .iconWishlistSelected {
  color: #86c232;
}

.div__cart-item-inventoryQuantity {
  align-items: center;
  margin: 5px 0px;
}

.div__cart-item-inventoryQuantity .div__dropdown-input {
  width: 94px;
  margin-left: 17px;
}

#dropdownInput {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 30px;
  width: 100%;
  border-radius: 2px;
}

.div__userCart-overview .MuiGrid-item {
  width: 100%;
}

@media (max-width: 1279px) {
  .div__cart-item-actions > div {
    font-size: clamp(1.2rem, 1.6vw, 2rem);
  }
  .div-uploadedImage {
    height: 100px;
    width: 100px;
    border-radius: 13px;
  }
}

@media (max-width: 769px) {
  .div__cart-item-actions > div {
    font-size: clamp(0.9rem, 2.5vw, 2rem);
  }

  .div__cart-item-actions {
    height: 8%;
  }
}
