.div__productList {
    margin: 20px 30px;
}

.div__productList-filters {
    width: 100%;
    display: flex;
    margin: 20px 8px;
    justify-content: space-between;
}

.div__productList-tableContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.div__inventoryList-filters p.lblInput {
    margin: 2px;
}

tr.dataRow td {
    padding: 15px;
}

svg.listIcon {
    color: #86c232;
    font-size: 25px;
}

.MuiDrawer-root.productDetailDrawer .MuiPaper-root {
    background-color: #707070;
}

.iconExpandRow {
    color: white;
    font-size: 24px;
}

.div__productList-filters .div__txtInput .lblInput{
    font-size: clamp(1.6rem,1.5vw,2rem);
}

.div__productList-filters .div__txtInput{
    width: 60%;
}

.div__productList-filters #btnInput{
    width: 25%;
    margin-top: 15px;
    font-size: 16px;
}

.div__productList-tableContainer .div__tableList-container th.headerColumn, .div__tableList-container tr.dataRow td, .div__tableList-container .noDataRow{
    font-size: clamp(1rem,1vw,1.5rem) !important;
}

.div__productList-tableContainer .MuiTablePagination-selectLabel, .div__productList-tableContainer .MuiTablePagination-selectIcon, .div__productList-tableContainer .MuiTablePagination-select.MuiSelect-select, .div__productList-tableContainer .MuiTablePagination-displayedRows, .div__productList-tableContainer .MuiTablePagination-actions .MuiSvgIcon-root{
    font-size: clamp(1rem,1vw,1.5rem) !important; 
}

.div_actionIcons{
    display: flex;
    justify-content: center;
}
