.tabletLandscape .vendorWalletDashboard {
    padding: 10px;
}

.tabletPortrait .vendorWalletDashboard {
    padding: 10px;
}

.tabletPortrait .vendorWalletDashboard__header {
    margin-left: 10px;
    width: 101%;
}

.tabletPortrait .vendorWalletDashboard__gridContainer{
    flex-direction: column;
    width: 100%;
}

.tabletPortrait .vendorWalletDashboard__gridContainer-walletBalance{
    width: 100%;
}

.tabletPortrait .vendorWalletDashboard__gridContainer-monthlySummary{
    width: 100%;
}

.tabletPortrait .div__detail-item.vendorDashboard__gridContainer--bankAccountsList,
.tabletPortrait .div__detail-item.walletBalance__card {
    width: 319%;
}

.tabletPortrait .vendorWalletDashboard__header--right {
    width: 35%;
}

.tabletPortrait .vendorWalletDashboard__monthlySummary{
    width: 154.5%;
}

.tabletPortrait .vendorWalletDashboard__gridContainer .vendorWalletDashboard__gridContainer-monthlySummary,
.tabletPortrait .vendorWalletDashboard__gridContainer .vendorWalletDashboard__gridContainer-bankAccountsList,
.tabletPortrait .vendorWalletDashboard__gridContainer .vendorWalletDashboard__gridContainer-walletBalance,
.tabletPortrait .vendorWalletDashboard__gridContainer .vendorDashboard__gridContainer--vendorTransactions {
    margin-left: 15px;
}