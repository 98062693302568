.mobile .myMemeSuggestions .myMemeSuggestions-searchInput{
    width: 90%;
}

.mobile .myMemeSuggestions-container .MuiGrid-item{
    width: 90%;
}

.mobile #btnInput.memeSuggestion_detail-addMemeSuggestion {
    width: 35%;
    font-size: clamp(0.8rem, 1.5vh, 1.5rem);
}

.mobile #iconMemeSuggestion{
    font-size: 30px;
}


.mobile .myMemeSuggestions-imgEmptyList {
    width: 212px;
    height: 224px;
}

.mobile .imgCardPreview,
.mobile .imgCardPreview{
    height: 275px;
    width: 208px;
}

@media (max-width:600px) {
    
    .MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--add .MuiDrawer-paper,
    .MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--view .MuiDrawer-paper {
        height: 100%;
        border-radius: 0px;
        padding: 15px;
    }

    .MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--adds .btnCloseDrawer 
    .MuiDrawer-root.myMemeSuggestions-memeSuggestionDrawer--view .btnCloseDrawer {
        margin-top: 20px;
    }
}