.mobile .profile-page__banner-footer__content-small,
.mobile .profile-page__banner-footer__content-big{
    margin: 0px 1%;
}

.mobile .profile-page__banner-overlay .MuiAvatar-circular.MuiAvatar-root {
    bottom: -16%;
}

.mobile .profile-page__banner-count,
.mobile .profile-page__banner-prompt{
    font-size: clamp(1rem,1.5vw,1.8rem);
}

.mobile .profile-page__banner-footer__left,
.mobile .profile-page__banner-footer__right {
    height: 166px;
}