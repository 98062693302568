.mobile .myTickets__filters-selectCategory {
  padding: 4px;
  width: 100px;
  height: 50px;
  background-color: white;
  color: #686868;
  margin: 1px;
}

.mobile .div__detail-item-actions div {
  font-size: clamp(0.9rem,1.8vw,2rem);
  cursor: pointer;
  text-align: center;
}

  .mobile .myTickets__ticketCard-content{
    padding: 10px 0px;
  }

  .mobile .div__detail-item-content.myTickets__ticketCard-content {
    padding: 10px 0px;
  }

  .mobile .myTickets__container .MuiTabs-root .MuiTab-textColorPrimary {
    font-size: clamp(0.85rem, 1vw, 1rem);
}

.mobile .MuiDrawer-root.myTickets-ticketDrawer--add .MuiDrawer-paper,
.mobile .MuiDrawer-root.myTickets-ticketDrawer--view .MuiDrawer-paper {
  height: 100%;
}

.mobile .myTickets__container{
  margin: 30px 10px;
}
 
  