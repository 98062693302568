.div__sizeChart-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.div__sizeChart-container p {
    color: #707070;
}

.div__itemSelected-details .lblItemTitle {
    font-size: clamp(1rem, 1.5vw, 2rem);
}

.div__sizeChart-content {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.div__sizeChart-content-header {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.lblSizeChart {
    align-self: center;
    font-size: clamp(1rem, 1.7vw, 2rem);
}

.imgSizeChart {
    width: 30%;
    aspect-ratio: 516/541;
    margin: 20px;
    margin: 110px 0px;
}

.div__sizeChart-content th.headerColumn,
.div__sizeChart-content tr.dataRow td {
    color: #707070;
    font-size: 19px;
    padding: 10px;
}

.div__sizeChart-content tr.dataRow,
.div__sizeChart-content th.headerColumn {
    border-bottom: 0.5px solid #dadada
}

.div__sizeChart-cartActions {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 20px auto;
}

.div__itemSelected-content .div-uploadedImage {
    width: 33%;
}


@media (min-width:640px) {

    .div-uploadedImage  .div__itemSelected-content .div-uploadedImage .imageItem{
        width: 80px;
        height: 80px;
    }
    
    .div__itemSelected-details{
        margin: 0px;
    }

}


@media (max-width : 960px){

    .div__sizeChart-container{
        flex-direction: column;
        align-items:center;
    }
    .div__sizeChart-content{
        width: 100%;
    }

    .sizeChartDrawer .btnCloseDrawer svg {
        margin-top: 30px;
        color: grey;
    }

    .div__tableList-container th.headerColumn, .div__tableList-container tr.dataRow td, .div__tableList-container .noDataRow {
        font-size: clamp(0.8rem, 1vw, 2rem) !important;
        
    }
    
}


@media (min-width: 640px) and (max-width: 960px){

    .imgSizeChart {
        width: 40%;
        aspect-ratio: 516/541;
        margin: 20px;
    }
}
 
@media (max-width: 640px){

    .imgSizeChart {
        width: 80%;
        aspect-ratio: 516/541;
        margin: 20px;
    }
}


.div__sizeChart-cartActions .btnAddToCart,
.div__sizeChart-cartActions .btnBuyNow {
    width: 50%;
}
