.profile-page__contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 5.5%;
}

.suggest-meme__btn {
  padding: 0;
  font-size: 13px;
}

.suggest-meme__btn-text {
  
}

.suggest-meme__btn-icon.css-i4bv87-MuiSvgIcon-root{
  height: 13px;
  width: 13px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.profile-page__gallery-card__container {
  width: 468px;
  height: 675px;
  padding: 21px 25px 36px;
  border-radius: 8px;
  background-color: #fff;
  margin: 17px 0px 0px 28px !important;
  align-items: center;
  display: flex;
  flex-direction: column!important;
}


.profile-page__gallery-card__container.displaced {
  margin-top: 42px !important;
}

.profile-page__card-img {
  width: 100%;
  aspect-ratio: 320/437;
  margin: 0 0 11px;
  border-radius: 4px;
  background-color: #e4e4e4;
}

.profile-page__gallery {
  justify-content: center;
}

.profile-page__card-description {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-page__card-description-heading {
  font-size: 16px;
  color: #b0b0b0;
  margin-bottom: 1px;

}

.profile-page__card-description-content {
  font-size: clamp(0.8rem,0.8vw,1rem);
  color: #585858;
}