.div__tableList-container, .listTable {
    width: 100%;
}

.listTable {
    width: 100%;
    border: 1px solid #707070;
    border-collapse: collapse;
    background-color: #212628;
}

.div__tableList-container th.headerColumn,
.div__tableList-container tr.dataRow td,
.div__tableList-container .noDataRow {
    color: white;
    text-align: center;
    font-weight: normal;
    font-size: clamp(1rem, 1.2vw, 2rem);
    padding: 10px;
}

.headerRow {
    border-bottom: 1px solid #707070;
}

tr.dataRow td {
    padding: 15px;
    text-align: center;
    border: none;
}

td.dataRowExpanded {
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
}

svg.listIcon {
    color: #86c232;
    font-size: clamp(1.3rem,1.5vw,2rem);
}

tbody .loadingProgress {
    color: white;
    padding: 15px;
}

.div__noData-message {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messageTitle,
.messageDescription {
    font-size: clamp(0.8rem,1.5vw,2rem);
    align-self: center;
    margin: auto;
    margin-top: 10px;
}

.messageTitle {
    font-size: clamp(0.8rem,1.5vw,2rem);
}

.MuiTablePagination-root {
    background-color: #212628;
    font-size: clamp(0.8rem,1.5vw,2rem);
    color: white;
    width: 100%;
}


.appContainer .MuiTablePagination-selectLabel,
.appContainer .MuiTablePagination-selectIcon,
.appContainer .MuiTablePagination-select.MuiSelect-select,
.appContainer .MuiTablePagination-displayedRows,
.appContainer .MuiTablePagination-actions .MuiSvgIcon-root {
    font-size: clamp(1rem,1vw,1.5rem);
    min-height: 20px;
    padding: 0px;
    color: white;
}