.mobile .profile-page__gallery-card__container.displaced{
    margin-top: 0px !important;
}

.mobile .profile-page__gallery-card__container.displaced,
.mobile .profile-page__gallery-card__container{
    margin: 15px !important;
    padding: 10px;
}

.mobile .profile-page__gallery-card__container {
    width: 221px;
    height: 323px;
}

.mobile .profile-page__banner-container {
    height: 250px;
}

.mobile .profile-page__banner-overlay .MuiAvatar-circular.MuiAvatar-root {
    bottom: -27%;
    height: 80px;
    width: 80px;
}