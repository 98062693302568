.vendorEmailOTP__formContainer {
    display: flex;
    justify-content: space-around;
}

.vendorEmailOTP__formContainer--left-header {
    font-size: clamp(1.2rem, 2.5vw, 2.1rem);
    color: white;
}

.vendorEmailOTP__formContainer--left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.vendorEmailOTP__formContainer--left-OTP {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 150px;
}

.vendorEmailOTP__formContainer--left-OTPMessage,
.vendorEmailOTP__formContainer--left-enteredEmail {
    font-size: clamp(1rem,1.5vw,1.4rem);
    color: #e0e0e0;
}

.vendorEmailOTP__formContainer--left-changeEmail {
    font-size: clamp(1rem,1.5vw,1.4rem);
    color: #86c232;
    margin: 0px 20px;
    cursor: pointer;
}

.div__txtInput .lblInput {
    font-size: clamp(1rem,1.5vw,2rem);
    margin: 5px 0px;
}

#btnInput.vendorEmailOTP__formContainer--left-verifyOTP {
    width: 342px;
    height: 62px;
    font-size: clamp(1rem,1.5vw,2rem);
    text-transform: uppercase;
    margin-top: 20px;
}

.vendorEmailOTP__formContainer--left-resendOTP {
    margin-top: 20px;
    font-size: clamp(0.95rem,1.5vw,1.6rem);
    color: white;
}

.vendorEmailOTP__formContainer--left-resendOTP-timeout {
    margin-left: 10px;
    color: #86c232;
}

.vendorEmailOTP__formContainer--left-resendOTP-action {
    font-size: clamp(1rem,1.5vw,1.6rem);
    color: #86c232;
    text-align: center;
    cursor: pointer;
}

.vendorEmailOTP__formContainer--right {
    width: 20%;
    aspect-ratio: 256/323;
    align-self: center;
}