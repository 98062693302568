
.mobile .imgLogoMemeT {
  height: 96px;
  width: 65px;
    margin-top: 30px;
  margin-left: 18px;
}

.mobile .lnkSocialMedia {
  height: 50px;
  width: 50px;
  margin-left: -3px;
  margin-top: 21px;
}

.mobile .div__footer-contactUs-details {
  margin: 0px;
}

.mobile .div__footer-contactUs{
  margin: 20px 0px;
}

.mobile .div__footer-pagesList{
  padding-right: 20px;
}

.mobile .div__footer-contactUs-socialMedia-links .lnkSocialMedia--icon{
  font-size: 25px;
}

.mobile .pageLink {
  margin: 5px 0px;
  padding: 10px;
}

.mobile .div__footer-pagesList-items .pageLink {
  flex-wrap: wrap;
}

.mobile .div__footer-pagesList {
  margin-top: 40px;
}

.mobile .div__footer-topContent{
  flex-direction: column;
}

.mobile .div__footer-pagesList-items{
  flex-direction: row;
  flex-wrap: wrap;
}
