.div__productCard-title,
.div__productCard-footer {
  display: flex;
  justify-content: space-between;
}

.div__productCard {
  display: flex;
  flex-direction: column;
  background-color: #212628;
  margin: 5px;
  cursor: pointer;
  width: 300px;
}

.productImage {
  width: 100%;
  aspect-ratio: 325/356;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.div__productCard__details {
  padding: 10px;
  flex: 1;
}

.productTitle {
  font-size: clamp(1rem, 1vw, 1.7rem);
}

.lblLanguage {
  margin: 5px 0px;
  font-size: clamp(0.8rem, 0.9vw, 1.2rem);
}

.div__productCard-price {
  text-align: center;
}

.productPrice {
  font-size: clamp(1rem, 1.5vw, 2.2rem);
}

.productDiscountedPrice {
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  text-decoration: line-through;
}

span.productDiscount {
  font-size: clamp(0.8rem, 1vw, 1.7rem);
  color: #86c232;
  display: inline-block;
  margin-left: 5px;
}

.div__productCard-rating {
  display: flex;
  margin: 15px 0px;
  font-size: 16px;
}

.div__productCard-rating span.MuiRating-iconEmpty {
  color: white;
}

.ratingsCount {
  margin: 3px 5px;
}

.iconWishlist, .iconWishlistSelected{
  border-radius: 25px;
  padding:5px;
  border: solid 4px #212628;
  color: white;
}

.iconWishlist {
  background:#686868;
}

.iconWishlistSelected {
  background-color: #86c232;
}

.div__productCard .MuiIconButton-root {
  justify-content: flex-start;
  margin-top: -28px;
}
