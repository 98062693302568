
.mobile .div__userWishlist-container {
    margin-top: 10px;
}

.mobile .div__userWishlist-header {
    margin: 10px 25px;
}

.mobile .div__userWishlist-results {
    justify-content: center;
}

.mobile .div__userWishlist-results .div__productCard {
    width: 320px;
}

.mobile .div__userWishlist-container .gridContent__emptyList-message{
    margin: 7px 0px;
}

.mobile .div__userWishlist-container .gridContent__emptyList-subMessage{
    margin: 7px 0px;
}

.mobile .imgEmptyWishlist {
    width: 40%;
    aspect-ratio: 447/322;
}
