/* .mobile  .MuiOutlinedInput-root.MuiInputBase-root {
    height: 57px;
} */

.mobile .cardPayment__CVV-ExpiryDate__Container{
    width: 100%;
    justify-content: space-between;
}

.mobile .cardPayment__ExpiryDate{
    margin: 0px;
}

.mobile .cardPayment__CVV,
.mobile .cardPayment__ExpiryDate{
    width: 45%;
}