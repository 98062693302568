.mobile .div__noSearchResults{
    width: 95%;
}

.mobile .imgNoSearchResults {
    width: 60%;
}

.mobile #btnInput.btnSuggestMemes {
    width: 80%;
    height: 50px;
    font-size: clamp(1rem, 1.5vh, 1.5rem);
}