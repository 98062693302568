.div__sizeSelector-container {
    display: flex;
    flex-direction: column;
}

.div__sizeSelector-sizes {
    display: flex;
    margin: 10px -2px;
}

.lblSelectSize {
    color: white;
    margin: 5px 10px;
    font-size: clamp(1rem,1.2vw,1.5rem);
}

.sizeOption, .sizeOptionSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    padding: 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.sizeOptionSelected {
    color: greenyellow;
    border: 1px solid greenyellow;
}

.sizeDisabled {
    opacity: 0.3;
    cursor: not-allowed;
}