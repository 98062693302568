.laptop .div__login-form-googleLogin {
  width: 300px;
}
.laptop .div__login-form-facebookLogin {
  width: 300px;
}
.laptop #btnInput.btnSignIn,
#btnInput.btnRegister {
  width: 300px;
  height: 40px;
}
