.tabletLandscape .div__aboutUs-languages-languageGraph {
    width: 641px;
    height: 283px;
    margin: 30px auto;
    object-fit: cover;
}

.tabletPortrait .div__aboutUs-languages-languageGraph {
    width: 567px;   
    height: 252px;
    margin: 30px auto;
    object-fit: cover;

}

.tabletLandscape .div__aboutUs-USP-imgPlatformUSP {
    width: 735px;
    height: 299px;
    margin: 30px auto;
    object-fit: cover;
}

.tabletPortrait .div__aboutUs-USP-imgPlatformUSP {
    width: 554px;
    height: 225px;
    margin: 12px auto;
    object-fit: cover;
}

.tabletPortrait .div__aboutUs-Designer-imgDesigner {
    width: 331px;
    height: 319px;
    margin: 30px auto;
    object-fit: cover;
}