.div-uploadedImage {
  width: 95%;
  height: auto;
  border-radius: 25px;
  object-fit: cover;
}
.div__imagesUploaded .div-uploadedImage {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 137px;
  border-radius: 26px;
  padding: 4px;
  margin: 0px 10px;
}

.div-uploadedImage-selected {
  border: 3px solid rgba(173, 255, 47, 0.75);
  border-radius: 10px;
  padding: 2px;
}

.div-uploadedImage .removeIcon {
  align-self: flex-end;
  margin-bottom: -40px;
}

.div-uploadedImage .removeIcon svg {
  color: crimson;
  font-size: 30px;
}
