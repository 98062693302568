.mobile .checkout__mainLayout__Container{
    width: 100%;
}

.mobile .checkout__mainLayout_left{
    width: 25%;
}

.mobile .checkout__mainLayout_right{
    width: 70%;
    padding: 0px 10px;
}

.mobile .checkout__header__logo {
    width: 24%;
}