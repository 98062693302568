.myMemeSuggestions-overview .myMemeSuggestions-card {
    margin: 0px
}

.myMemeSuggestions-card {
    width: 100%;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.27);
}

.myMemeSuggestions-card-content-image {
    width: 190px;
    height: 155px;
    border-radius: 8px;
}

.myMemeSuggestions-card-content {
    display: flex;
    padding: 10px 20px;
}

.myMemeSuggestions-card-content-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 20px;
    color: white;
}

.myMemeSuggestions-card-content-details-title,
.myMemeSuggestions-card-content-details-description {
    font-size: clamp(1.1rem,3vw,1.3rem);
}

.myMemeSuggestions-card-content-details-descHeader {
    font-size: clamp(1rem,3vw,1.3rem);
    color: #b7b7b7;
}

.myMemeSuggestions-card .div__detail-item-actions div {
    font-size: clamp(1rem,1.5vw,1.3rem);
    cursor: pointer;
}