.orderFeedback__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.orderFeedback__form-header {
    color: #585858;
    font-size: clamp(1rem,1.7vw,2.2rem);
}

.orderFeedback__form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.orderFeedback__form--leftRight {
    display: flex;
    width: 100%;
}

.orderFeedback__form--left .orderDetail__content--left-item-details,
.orderFeedback__form--left .orderDetail__content--left-item-header {
    padding: 10px;
}

.orderFeedback__form--left-image {
    width: 196px;
    height: auto;
    margin : 0px 5%;
}

.orderFeedback__form--right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    height: auto;
}

.orderFeedback__form-rating {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.orderFeedback__form-rating-header {
    font-size: clamp(1rem,1.7vw,2.2rem);
    color: #585858;
    margin: 10px 0px;
}

.orderFeedback__form-rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
}

.orderFeedback__form-rating-container svg {
    color: #d3d3d3;
    font-size: 40px;
}

.orderFeedback__form-rating-container svg.MuiRating-iconFilled {
    color: #86c232;
    font-size: 65px;
}

.orderFeedback__form-rating-label {
    font-size: clamp(1rem,1.7vw,2.2rem);
    text-align: center;
    color: #d3d3d3;
}

.orderFeedback__form-rating-error {
    color: #585858;
    font-size: 22px;
    margin-top: 20px;
    color: crimson;
}

.orderFeedback__form-questions {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 30px 15px;
}

.orderFeedback__form-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.orderFeedback__form .orderFeedback__form-descriptionInput {
    margin: 20px 0px;
    width: 100%;
}

.orderFeedback__form-descriptionInput input {
    font-size: clamp(1rem, 2vw, 1.5rem);
    width: 100%;
}

.orderFeedback__form-description-label {
    text-align: center;
    margin: 10px 0px;
    color: #d3d3d3;
    font-size: clamp(1rem,1.5vw,2rem);

}

#btnInput.orderFeedback__form-submit {
    width: 200px;
    height: 60px;
    font-size: clamp(1rem,1.5vw,2rem);
    text-transform: uppercase;
    border-radius: 5px;
}

.orderFeedback__form span.errorMessage {
    color: crimson !important;
}

.orderFeedback__form-buttons {
    display: flex;
    justify-content: center;
}

@media (max-width : 675px){

    .orderFeedback__form-questions{
        flex-direction: column;
    }
}

@media (max-width: 540px){
    .orderFeedback__form--left-image{
        display: none;
    }
    .orderFeedback__form--leftRight{
        justify-content: center;
    }
}