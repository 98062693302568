.memeSuggestionDetail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
}

.memeSuggestionDetail-container__heading {
  font-size: clamp(1rem, 2vw, 2.5rem);
  font-weight: normal;
  line-height: 1.17;
  color: #585858;
  text-transform: uppercase;
}

.memeSuggestionDetail-container__view {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.memeSuggestionDetail-container__leftGallery {
    width: 40%;
}

.memeSuggestionDetail-container__rightGallery {
    width: 60%;
}

.memeSuggestionDetail-container__leftGallery-memeSuggestionImages .memeSuggestionDetail-container__leftGallery-memeSuggestionImages-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 410px;
    overflow-y: auto;
}

.memeSuggestionDetail-container__leftGallery-memeSuggestionImages .memeSuggestionDetail-container__leftGallery-memeSuggestionImages-content::-webkit-scrollbar {
    display: none;
}
  
.memeSuggestionDetail-container__leftGallery-memeSuggestionImages .memeSuggestionDetail-container__leftGallery-memeSuggestionImages-content {
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.memeSuggestionDetail-container__leftGallery-noAttachments {
    font-size: 24px;
    color: #707070;
}

.memeSuggestionDetail-container__leftGallery-memeSuggestionImages .memeSuggestionDetail-container__leftGallery-memeSuggestionImages-header {
    padding: 10px;
}

.memeSuggestionDetail-container__leftGallery-memeSuggestionImage {
    width: 100%;
    min-height: 237px;
    margin: 21px 26px 32px 32px;
    border-radius: 29px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.memeSuggestionDetail-container__rightGallery-memeSuggestionDetails-header {
    display: flex;
    justify-content: space-between;
}

.memeSuggestionDetail-container__rightGallery-memeSuggestionDetails-content {
    display: flex;
    flex-direction: column;
}

.memeSuggestionDetail-container__actionButtons .memeSuggestionDetail-container__actionButtons-approve,
.memeSuggestionDetail-container__actionButtons .memeSuggestionDetail-container__actionButtons-approve:hover {
    width: 50%;
    height: 60px;
    border-radius: 5px;
    background-color: #86c232;
    color: white;
    font-size: clamp(1rem,1vw,1.5rem);
    text-transform: uppercase;
    margin: 10px;
}

.memeSuggestionDetail-container__actionButtons .memeSuggestionDetail-container__actionButtons-reject,
.memeSuggestionDetail-container__actionButtons .memeSuggestionDetail-container__actionButtons-reject:hover {
    width: 50%;
    height: 60px;
    border-radius: 5px;
    border: solid 3px #86c232;
    color: #86c232;
    font-size: clamp(1rem,1vw,1.5rem);
    margin: 10px;
}

.memeSuggestionDetail-container span.errorMessage {
    color: crimson !important; 
    font-size: clamp(1rem,1vw,1.5rem) !important;
}

.txtAreaInput.memeSuggestionDetail-container__comment {
    width: 1078px;
    height: 156px !important;
    margin: 10px;
    border-radius: 11px;
    border: solid 1px #a0a0a0;
    font-size: 24px;
}

.memeSuggestionDetail-container__actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}