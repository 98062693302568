.vendorOnboarding {
    display: flex;
    width: 100%;
    padding-top: 50px;
}

.vendorOnboarding__left {
    width: 300px;
    height: 300px;
    align-self: center;
    margin-left: 10px;
}

.vendorOnboarding__right {
    width: 50%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.vendorOnboarding__right-header {
    font-size: clamp(1.2rem, 2.5vw, 2.1rem);
    margin-bottom: 50px;
}

.vendorOnboarding__right-steps {
    width: 100%;
}

.vendorOnboarding__right-stepLabel .MuiStepLabel-label,
.vendorOnboarding__right-stepLabel .MuiStepLabel-label.Mui-completed {
    font-size: clamp(0.8rem,1vw,2rem);
    color: #9f9f9f;
}

.vendorOnboarding__right-stepLabel .MuiStepLabel-label.Mui-completed {
    color: white;
}

.vendorOnboarding .MuiStepConnector-line {
    border: 2px solid #707070;
    margin: 0px -1px;
    border-radius: 5px;
}

.vendorOnboarding .Mui-completed .MuiStepConnector-line {
    border: 2px solid #85c340;
}

.vendorOnboarding .vendorOnboarding-icon {
    background-color: #707070;
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: -6px;
    text-align: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    display: flex;
}

.vendorOnboarding .Mui-completed .vendorOnboarding-icon {
    background-color: #85c340;
}

.vendorOnboarding__right-content {
    padding: 50px 25px;
    width: 83%;
}